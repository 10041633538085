import React, {useEffect, useState} from "react";
import general from "../../general";
import $, { data } from "jquery";
import {useReactive, useScroll} from "ahooks";
import KeepScroll from "../../lib/KeepScroll";
import Dialog from "../../lib/Dialog";

const types = [
  {
    id: 'money',
    text: '现金',
  },
  {
    id: 'ptb',
    text: '平台币',
  },
  {
    id: 'coin',
    text: '金币',
  },
  {
    id: 'voucher',
    text: '代金券',
  },
];

export default props => {
  const [typeIndex, setTypeIndex] = useState(parseInt(props.query.type) || 0);
  const datas = useReactive(types.map(item => ({
    id: item.id,
    text: item.text,
    init: false,
    loading: null,
    page: 1,
    more: null,
    datas: []
  })));
  const load = (parms = {}) => {
    let data = datas[typeIndex];
    if (data.loading) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    let tmp = {
      money_type: data.id,
      pagination: {count: 20, page: data.page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/bill_get_list', tmp)
        .finally(() => {
          data.loading = false;
          Dialog.close();
        })
        .then(response => {
          data.init = true;
          let result = response.data.data;
          if (parms.page) {
            data.page = parms.page;
          }
          data.datas = parms.page === 1 || !parms.page ? result.list : [...data.datas, ...result.list];
          data.more = result.paginated.more;
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active && !datas[typeIndex].init) {
      load();
    }
  }, [props.active, typeIndex]);

  const scroll = useScroll();
  useEffect(() => {
    let data = datas[typeIndex];
    if (props.active && data.init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && data.init) {
        load({page: data.page + 1});
      }
    }
  }, [scroll, props.active]);
  // console.log(datas);

  return <div className="user-bill">
    <div className="user-bill-head">
      <div className="items">
        {types.map((item, index) => 
          <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>
            <img src={`resources/user/order_top_icon${index}.png`} alt="" />
            {item.text}
          </div>)}
      </div>
      <div className="thead">
        <div className="th time">时间</div>
        <div className="th amount">金额</div>
        <div className="th explain">备注</div>
      </div>
    </div>
    <div className="user-bill-body">
      {datas.map((item, index) =>
          <div className={'type-item' + (index === typeIndex ? ' active' : '')}>
            <KeepScroll active={index === typeIndex}>
              {item.datas.map(item =>
                  <div className="bill-item">
                    <div className="td time">{item.date_time}</div>
                    <div className={"td amount " + (item.amount.indexOf('-') != -1 ? "amount-block"  : "") }>  {item.amount}</div>
                    <div className="td explain">
                      {item.comment}
                      {/* {
                        item.nameRemark ?
                        <p className="nameRemark">
                          {item?.nameRemark}
                        </p>
                        : null
                      } */}
                    </div>
                  </div>)}
              {item.datas > 0 && !item.more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
              {item.datas.length === 0 && datas.init ? <div className="recovery-empty"><img src="/resources/user/bill_empty.png" alt=""/>暂无{types[typeIndex].text}记录</div> : null}
            </KeepScroll>
          </div>)}
      {/* {datas.length === 0 ? <div className="recovery-empty"><img src="/resources/user/bill_empty.png" alt=""/>暂无{types[typeIndex].text}记录</div> : null} */}
    </div>
  </div>;
};