import {useState} from "react";
import general from "../../general";
import MobileDetect from "mobile-detect";
import Dialog from "../../lib/Dialog";
import ImagePicker from "../util/ImagePicker";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default props => {
  const [data, setData] = useState({
    mobile_model: mobileDetect.os() + ' ' + mobileDetect.version(mobileDetect.os()),
    mobile_system_code: mobileDetect.os() + ' ' + mobileDetect.version(mobileDetect.os()),
    type: 'suggest'
  });
  const [imgs, setImgs] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const submit = () => {
    if(!data.user_name) {
      Dialog.info("请输入投诉对象");
      return false;
    }
    if(!data.user_connect) {
      Dialog.info("请输入投诉对象联系方式");
      return false;
    }
    if(!data.content) {
      Dialog.info("请输入投诉内容");
      return false;
    }
    if(!data.contact) {
      Dialog.info("请输入联系人");
      return false;
    }
    if(!data.qq) {
      Dialog.info("请输入联系人qq");
      return false;
    }
    if (submiting) {
      return;
    }
    setSubmiting(true);
    Dialog.loading();
    var formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    imgs.forEach((img, index) => {
      formData.append('imgs[' + index + ']', img.file);
    });
    general.axios.post('/base/feedback', formData)
        .finally(() => {
          setSubmiting(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            Dialog.success('提交成功', general.history.goBack());
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  return <div className="feedback-game">
    <div className="feedback-game-body">
      <div className="feedback-block">
        <div className="input-item">
          <div className="name">投诉对象</div>
          <div className="value">
            <input type="text" placeholder="（必填，例如：XX部门－小花）" onChange={e => setData({...data, user_name: e.target.value})}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">投诉对象联系方式</div>
          <div className="value">
            <input type="text" placeholder="必填" onChange={e => setData({...data, user_connect: e.target.value})}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">投诉内容</div>
          <div className="value">
            <input type="text" placeholder="必填" onChange={e => setData({...data, content: e.target.value})}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">联系人</div>
          <div className="value">
            <input type="text" placeholder="必填" onChange={e => setData({...data, contact: e.target.value})}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">联系人QQ</div>
          <div className="value">
            <input type="text" placeholder="必填" onChange={e => setData({...data, qq: e.target.value})}/>
          </div>
        </div>
      </div>
      <div className="feedback-block">
        <div className="name">请上传3~5张图片</div>
        <ImagePicker multiple={true} max={5} files={imgs} setFiles={setImgs}/>
      </div>
      <div className="feedback-block">
        温馨提示：<br/>
        1、受理对本公司游戏员工或其他关联企业工作中的失职，营私舞弊，弄虚作假等违反职业道德准则的行为的投诉举报。<br/>
        2、对举报人信息进行严格保密是我们最基础的职责，并要求被举报者不得对举报人实施打击报复行为，一经发现，严肃处理。<br/>
        3、我们鼓励使用自己真实姓名进行实名举报，优先办理实名举报并及时反馈受理情况及通报处理结果。<br/>
        4、游戏或平台相关的问题呢，请勿在此提交，可联系客服解决。<br/>
      </div>
    </div>
    <div className="feedback-operate">
      <div className={'btn ' + (submiting ? 'disable' : '')} onClick={submit}>确认提交</div>
    </div>
  </div>;
};