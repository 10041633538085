import general from "../../general";
import React, {useEffect, useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Dialog from "../../lib/Dialog";
import useActiveState from "../../lib/useActiveState";
import useUrlState from "@ahooksjs/use-url-state";

const tasks = [
  {
    name: '每日登陆游戏',
    reward: '＋2金币',
    id: 'loginGame',
    info: '每日登录游戏就可领',
    operate: '去完成',
  },
  {
    name: '每日首充',
    reward: '＋8金币',
    id: 'recharge',
    info: '每日只要首充，即可获得奖励',
    operate: '去完成',
  },
  /*{
    name: '发布话题',
    reward: '＋2金币',
    id: '',
    info: '游戏圈子中发布优质话题，即可获取金币',
    operate: '去发布',
  },*/
  // {
  //   name: '微信朋友圈分享',
  //   reward: '＋2金币',
  //   id: 'wxshare',
  //   info: '分享到朋友圈，结伴游戏更畅快',
  //   operate: '去分享',
  // },
  // {
  //   name: 'QQ空间分享',
  //   reward: '＋2金币',
  //   id: 'qqkongjian',
  //   info: '分享到QQ空间即可获取金币',
  //   operate: '去分享',
  // },
];

export default props => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [shareUrl, setShareUrl] = useState([]);
  const [params] = useUrlState({}, {navigateMode: 'replace'});
  const [user] = useActiveState('user');

  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios.post('/user/task/getTaskDataByName', {type: 'day'})
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(response => {
        setLoaded(true);
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {

          let parAgent = "rr2f";
          if(params.agent) {
            parAgent = params.agent;
          }

          let shareAgent = user.isLogin ? user.agent : parAgent;
          let shareUrl = "https://webbox.99maiyou.cn/#/task/day?agent=" + shareAgent;
          setShareUrl(shareUrl);

          console.log(result.data.task_info);
          setData(result.data.task_info);
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }
  console.log(shareUrl);
  useEffect(() => {
    if (props.active && (!loaded || props.action === 'POP')) {
      load();
    }
  }, [props]);

  const [receiving, setReceiving] = useState(false);
  const handle = task => {
    let state = data[task.id];
    if (!state.taked) {
      if (state.completed) {
        if (receiving) {
          return;
        }
        setReceiving(true);
        Dialog.loading();
        general.axios.post('/user/task/takeBalance', {name: task.id})
          .finally(() => {
            setReceiving(false);
            Dialog.close();
          })
          .then(res => {
            let result = res.data;
            if (!result.status.succeed) {
              Dialog.error(result.status.error_desc);
            } else {
              Dialog.success('领取成功');
              data[task.id].completed = true;
              data[task.id].taked = true;
              setData({...data});
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
      } else {
        if (task.id === 'loginGame') {
          Dialog.alert('温馨提示', '每日登陆游戏就可领');
        } else if (task.id === 'recharge') {
          Dialog.alert('温馨提示', '仅计算现金/平台币充值游戏');
        } /*else if (task.id === 'wxshare') {

        } else if (task.id === 'qqkongjian') {

        }*/
      }
    }
  }
// console.log(data);
  return <div className="task-day" style={{backgroundImage: 'url(/resources/task/day-bg.png)'}}>
    <div className="day-head">
      <img src="/resources/task/day-title-milu.png" alt=""/>
    </div>
    <div className="day-body">
      <div className="info"><img src="/resources/task/day-info.png" alt=""/></div>
      <div className="tasks">
        {tasks.map(task => {
          let el = <div className="task" onClick={() => handle(task)}>
            <div className="icon"><img src={'/resources/task/day-' + (task.id) + '.png'} alt=""/></div>
            <div className="info">
              <div className="name">
                {task.name}
                {/* <div className="progress">{data[task.id]?.success_num} / {data[task.id]?.total}</div> */}
                <div className="reward">+{data[task.id]?.balance}金币</div>
              </div>
              <div className="desc">{task.info}</div>
            </div>
            <div className={'operate' + (data[task.id]?.completed ? (data[task.id]?.taked ? ' completed' : ' complete') : '')}>
              {data[task.id]?.completed ? (data[task.id]?.taked ? '已完成' : '领取') : (task.operate + '>')}
            </div>
          </div>;
          if (task.id === 'wxshare' || task.id === 'qqkongjian') {
            return <CopyToClipboard text={shareUrl} onCopy={() => Dialog.info('已复制链接')}>
              {el}
            </CopyToClipboard>;
          }
          return el;
        })}
      </div>
    </div>
  </div>;
};