import React, {useEffect, useState} from "react";
import general from "../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useScroll, useLockFn, useMount} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import useActiveState from '../../lib/useActiveState';

// export default ({active, type}) => {
export default props => {
  const {aplus_queue} = window;
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [init, setInit] = useState(false);
  const [data, setData] = useState();

  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    await general.axios.post('/transferV2/ruleDesc')
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        setData(data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  return init ? <div className="around-rule">
    <div className="rule-info" >
      <div className="icon">
        <Image src="/resources/around/rule.png"/>
      </div>
      <div className="subject">
        <div className="text">
          {/* 本平台的游戏，因各种不可抗力原因，导致个别游戏停服或玩家；想换新游玩，为了弥补玩家所付出的心血，平台特推出转游福利。 */}
          {data?.content}
        </div>
      </div>
    </div>

    <div className="title-name">{data?.question?.title}</div>

    <div className="rule-list">
      {
        data?.question?.answerItems.map(item => <p>{item}</p>)
      }
      {/* <p>
      1.玩家在游戏中的现金充值部分（不包含优惠券抵扣）的金额按1:1的比例转化成转游点，充值金额从2023.01.01日起开始计算；
      </p>
      <p>
      2.在转游列表中找到想玩的游戏，查看兑换条件，兑换转游礼包或代金券；
      </p>
      <p>
      3.已兑换的礼包和代金券可前往我的礼包和代金券处查看，礼包在游戏中可兑换成道具，代金券在游戏支付时直接抵扣；
      </p>
      <p>
      4.转游点只能用于兑换可转游游戏中的福利，不可做其他用处。
      </p>
      <p>
      *若您对转游福利还有其他疑问，可联系客服进行咨询
      </p> */}
    </div>

  </div> : null;
};