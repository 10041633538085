import {useMount} from "ahooks";
import moment from "moment";
import Dialog from "../../lib/Dialog";

export default props => {
  useMount(() => {
    let voucher = props.voucher;
    // console.log(voucher);
    Dialog.alert('使用方法', <div className="voucher-pop-desc">
      可用范围：仅限《{voucher.game_name}》充值使用<br/>
      有效期：
      {voucher.voucher_type == 2 ? voucher?.useEndDateTime : 
      <>{moment(voucher.start_time * 1000).format('YYYY-MM-DD')} ~ {moment(voucher.end_time * 1000).format('YYYY-MM-DD')}</>
      }<br/>
      使用条件：{voucher.meet_amount * 1 ===0 ? '任意金额使用' : '满' + voucher.meet_amount * 1 + '元可用'}<br/>
      使用方法：<br/>
      1.使用App账号登录游戏；<br/>
      2.在游戏内购买道具；<br/>
      3.在支付页面选择可用的优惠券，完成支付。<br/>
      使用说明：<br/>
      <div dangerouslySetInnerHTML={{__html : voucher.useDesc.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}></div>
    </div>, () => props.setVoucher(null), {btn: '我知道了'});
  });

  return <>

  </>;
};