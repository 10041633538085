import React, { useState, useEffect } from 'react'
import { Popup, Image } from 'antd-mobile'
import useActiveState from '../../lib/useActiveState'
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Dialog from '../../lib/Dialog'
import './css/Mask.css'
import { inQQ, inWechat } from '../wechat/Index';

export default function CopyMask() {

  const [app] = useActiveState('app');
  const [index,setIndex] = useState(0)

  useEffect(() => {
    if (index == 0) {
      setTimeout(() => {
        setIndex(1)
      }, 2000);
    }else if (index == 1) {
      setTimeout(() => {
        setIndex(2)
      }, 3000);
    }else {
      setTimeout(() => {
        setIndex(0)
      }, 5000);
    }
    
  }, [index])

  return (
    <div>
      <Popup className="copy-mask-index"
        visible={!inQQ() && !inWechat()}
        stopPropagation={['click']}
        bodyStyle={{
          borderRadius: '13px',
          overflow: 'hidden',
          margin: '20px',
          width: 'calc(100% - 40px)'
        }}>
        <div className="copy-mask-container">
          <Image height={178} src={`resources/mask/copy-${index+1}.gif`}/>
          <div className="index">
            第{index+1}步
          </div>
        </div>
        <div className="mask-bottom">
          <div className="mask-bottom-content">
            <div className="mask-bottom-content-desc">
              请使用
              <div className="mask-bottom-content-block">
                <Image className="mask-bottom-icon" src="resources/mask/safari.png"/>
                <span className="mask-bottom-name">Safari</span>
              </div>
              浏览器打开链接
            </div>
            <div className="mask-bottom-content-desc">
              安装
              <div className="mask-bottom-content-block">
                <Image className="mask-bottom-icon" src={app.appicon}/>
                <span className="mask-bottom-name">{app.appname}</span>
              </div>到桌面即可体验游戏
            </div>
          </div>
          <CopyToClipboard text={window.location.href} onCopy={() => Dialog.info('已复制链接')}>
            <div className="mask-bottom-copy-action">复制链接</div>
          </CopyToClipboard>
        </div>
      </Popup>
    </div>
  )
}
