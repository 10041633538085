import React, { useState, useEffect, useRef } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { DomEditor, 
         IEditorConfig,
         IToolbarConfig, 
         createEditor,
         IButtonMenu, 
         IDomEditor
        } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { useParams } from 'react-router-dom'
import './css/publish.css'
import general from '../../../../../general'
import Dialog from '../../../../../lib/Dialog'
import { BigPlayButton, ControlBar, Player, Shortcut } from 'video-react'
import { useHistory } from 'react-router-dom'
import { NavLink } from '../../../../../lib/Navigation'
import URI from "urijs";
import Compressor from 'compressorjs'
import { useLocalStorageState, useUnmount } from 'ahooks'

export default function Publish(props) {

  const {tagid,topic_id} = props.query
  const [mediaType,setMediaType] = useState(0)
  const [videoPath,setVideoPath] = useState()
  const [path,setPath] = useState()
  const [complete,setComplete] = useState(0)
  const [loading,setLoading] = useState(false)
  const [playing,setPlaying] = useState(false)
  const [title,setTitle] = useState('')
  const [titleLength,setTitleLength] = useState(0)
  const titleRef = useRef()
  const videoDom = useRef()
  const histyry = useHistory()
  const draftKey = 'draft-'+topic_id
  const [draft,setDraft] = useLocalStorageState(draftKey)
  const [save,setSave] = useState(true)

  useEffect(()=>{
    if (props.active){
      props.setTitle(tagid === '1' ? '发表评论' : tagid === '2' ? '发表攻略' : '问大神')
    }
  },[props.active])

  // editor 实例
  const [editor, setEditor] = useState()                   

  // 编辑器内容
  // const [html, setHtml] = useState('<p>hello</p>')
  const [html, setHtml] = useState()

  // 模拟 ajax 请求，异步设置 html
  useEffect(() => {
      setTimeout(() => {
          // setHtml('<p>hello world</p>')
          // console.log('qqq',draft);
          if (draft){
            setHtml(draft?.content)
            setMediaType(draft?.mediaType)
            setTitleLength(draft?.title.length)
            if (draft?.videoPath){
              setVideoPath(draft.videoPath)
              setPath(draft.path)
            }
          }
      }, 200)
  }, [])

  // 工具栏配置
  // const toolbar = DomEditor.getToolbar(editor)
  // const curtoolbarConfig = toolbar?.getConfig()
  // console.log('toobal',curtoolbarConfig);

  // 编辑器配置
  const editorConfig = {                         
      placeholder: '请输入内容，5～1000字以内',
      MENU_CONF: {}
  }

  const toolbarConfig = {}
  toolbarConfig.toolbarKeys = [
    'uploadImage','bold', 'italic', 'underline'
  ]

  // toolbarConfig.insertKeys = {
  //   index: 1, // 插入的位置，基于当前的 toolbarKeys
  //   keys: ['uploadImagea']
  // }

  // 自定义校验图片
  function customCheckImageFn(src, alt, url) {                                                   
    if (!src) {
        return
    }
    if (src.indexOf('http') !== 0) {
        return '图片网址必须以 http/https 开头'
    }
    return true

    // 返回值有三种选择：
    // 1. 返回 true ，说明检查通过，编辑器将正常插入图片
    // 2. 返回一个字符串，说明检查未通过，编辑器会阻止插入。会 alert 出错误信息（即返回的字符串）
    // 3. 返回 undefined（即没有任何返回），说明检查未通过，编辑器会阻止插入。但不会提示任何信息
  }

  // 转换图片链接
  function customParseImageSrc(src) {              
    if (src.indexOf('http') !== 0) {
        return `http://${src}`
    }
    return src
  }

  editorConfig.MENU_CONF['lineHeight'] = {
      lineHeightList: ['1']
  }

  editorConfig.hoverbarKeys = {
      'text': {
          // 如有 match 函数，则优先根据 match 判断，而忽略 element type
          match: (editor, n) => {                       
              // 可参考下文的源码
          },
          menuKeys: [], // 定义你想要的 menu keys
      },
      'image': {
          match: (editor, n) => {                       
              // 可参考下文的源码
          },
          menuKeys:[]
      }
  }

  editorConfig.MENU_CONF['uploadImage'] = {
    server: 'http://api3.app.wakaifu.com/base/community/image',
    // form-data fieldName ，默认值 'wangeditor-uploaded-image'
    fieldName: 'img',

    // 单个文件的最大体积限制，默认为 2M
    maxFileSize: 10 * 1024 * 1024, // 1M

    // 最多可上传几个文件，默认为 100
    maxNumberOfFiles: 3,

    // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
    allowedFileTypes: ['image/*'],

    // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
    meta: {
      type: 'community'
    },

    // 将 meta 拼接到 url 参数中，默认 false
    metaWithUrl: false,

    // 自定义增加 http  header
    // headers: {
    //     Accept: 'text/x-json',
    //     otherKey: 'xxx'
    // },

    // 跨域是否传递 cookie ，默认为 false
    withCredentials: true,

    // 超时时间，默认为 10 秒
    timeout: 5 * 1000, // 5 秒

    // 上传之前触发
    // onBeforeUpload(file: File) { // TS 语法
    onBeforeUpload(file) {    // JS 语法
      // file 选中的文件，格式如 { key: file }
      return file

      // 可以 return
      // 1. return file 或者 new 一个 file ，接下来将上传
      // 2. return false ，不上传这个 file
    },

    // 上传进度的回调函数
    onProgress(progress: number) {  // TS 语法
    // onProgress(progress) {       // JS 语法
        // progress 是 0-100 的数字
        console.log('progress', progress)
    },

    // 单个文件上传成功之后
    onSuccess(file: File, res: any) {  // TS 语法
    // onSuccess(file, res) {          // JS 语法
        console.log(`${file.name} 上传成功`, res)
    },

    // 单个文件上传失败
    onFailed(file: File, res: any) {   // TS 语法
    // onFailed(file, res) {           // JS 语法
        console.log(`${file.name} 上传失败`, res)
    },

    // 上传错误，或者触发 timeout 超时
    onError(file: File, err: any, res: any) {  // TS 语法
    // onError(file, err, res) {               // JS 语法
      console.log(`${file.name} 上传出错`, err, res)
    },

    // customInsert(res: any, insertFn: InsertFnType) {  // TS 语法
    // // customInsert(res, insertFn) {                  // JS 语法
    //     // res 即服务端的返回结果
    //     // 从 res 中找到 url alt href ，然后插入图片
    //     insertFn(res.data.path, 'alt', 'href')
    // },

    async customUpload(file: File, insertFn: InsertFnType) {  // TS 语法
      // async customUpload(file, insertFn) {                   // JS 语法
      console.log(file);
      const options = {
        maxWidth: 800,
        maxHeight: 600,
        mimeType:['image/png', 'image/jpeg'],
        quality: 0.4,
        success:result=>{
          console.log(result);
          const newFile = new File([result],result.name,{type:result.type})
          const formData = new FormData()
          formData.append('img',newFile)
          formData.append('type','community')
          general.axios.post('/base/community/image',formData)
          .then(res=>{
            const {status,data} = res.data
            if (status.succeed){
              insertFn(data.path, "alt", "href")
            }else{
              Dialog.error(status.error_desc)
            }
          })
        }
      };

      new Compressor(file,options)
      
    }

  }

  // 插入图片
  editorConfig.MENU_CONF['insertImage'] = {
    onInsertedImage(imageNode) {                    
        if (imageNode == null) return

        const { src, alt, url, href } = imageNode
        console.log('inserted image', src, alt, url, href)
    },
    checkImage: customCheckImageFn, // 也支持 async 函数
    parseImageSrc: customParseImageSrc, // 也支持 async 函数
  }
  // // 编辑图片
  // editorConfig.MENU_CONF['editImage'] = {
  //   onUpdatedImage(imageNode: ImageElement | null) {  // TS 语法
  //   // onUpdatedImage(imageNode) {                    // JS 语法
  //       if (imageNode == null) return

  //       const { src, alt, url } = imageNode
  //       console.log('updated image', src, alt, url)
  //   },
  //   checkImage: customCheckImageFn, // 也支持 async 函数
  //   parseImageSrc: customParseImageSrc, // 也支持 async 函数
  // }

  // 及时销毁 editor ，重要！
  useEffect(() => {
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
    }
  }, [editor])

  useUnmount(()=>{
    if (save && (title.length > 0 || html !== '<p><br></p>')){
      const tmp = {
        mediaType,
        tagid,
        title,
        content:html,
        topic_id,
        video:mediaType === 1 && path ? path : '',
        videoPath:mediaType === 1 && videoPath ? videoPath : ''
      }
      console.log(tmp);
      setDraft(tmp)
      Dialog.info('已保存到草稿箱')
    }
  })

  const fileChange = (e)=>{
    if (e.target.files.length == 0){
      return
    }
    const file = e.target.files[0]
    if (!file.size){
      return
    }
    setComplete(0)
    const formData = new FormData()
    formData.append('video',file)
    formData.append('type','community')
    general.axios.post('/base/community/video',formData,{
      onUploadProgress:(progressEvent)=>{
        if (progressEvent.lengthComputable){
          let complete = Math.round((progressEvent.loaded / progressEvent.total) * 10000) / 100.0;
          setComplete(complete)
        }
      }
    })
    .then(res=>{
      const {status,data} = res.data
      if (status.succeed){
        setVideoPath(data.reveal_path)
        setPath(data.path)
      }else{
        Dialog.error(status.error_desc)
      }
    })

  }

  const publishAction = ()=>{
    if (html === '<p><br></p>' || html.length === 0){
      Dialog.info('请输入内容')
      return
    }
    if (loading){
      return
    }
    setLoading(true)
    Dialog.loading()
    const tmp = {
      tagid,
      title:titleRef.current.value || '',
      content:html,
      topic_id,
      video:path
    }
    general.axios.post('/base/community/communityAdd',tmp)
    .finally(()=>{
      setLoading(false)
      Dialog.close()
    })
    .then(res=>{
      const {status,data} = res.data
      if (status.succeed){
        setSave(false)
        setDraft()
        Dialog.success('发布成功', () => {
          histyry.goBack()
        })
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  return (
    <div className='publish-index'>
      <div className="container">
        <div className="type">
          <div className="action">
            <span onClick={()=>setMediaType(0)} className={"text " + (mediaType === 0 ? 'active' : '')}>发布图文</span>
            <span onClick={()=>setMediaType(1)} className={"text " + (mediaType === 1 ? 'active' : '')}>发布视频</span>
          </div>
          <div className="publish" onClick={publishAction}>发表</div>
        </div>
        {mediaType === 1 ? 
        <div className='video'>
          <div className="upload">
            <img src="resources/community/add.png" alt="" width='26px'/>  
            <div className='text'>点击上传视频</div>
            <input className="file" type="file" accept="video/*" onChange={fileChange}></input>
            <div className="mask" style={{display:complete > 0 ? '' : 'none'}}>
              <div className="progress" style={{display:complete < 100 ? '' : 'none'}}>
              上传中（{complete}）...
              </div>
            </div>
            <div className="player" style={{display:videoPath ? '' : 'none'}}>
              <Player ref={videoDom}
                autoPlay={true}
                playsInline={true}
                src={videoPath}
              >
                <Shortcut></Shortcut>
                <BigPlayButton position='center'></BigPlayButton>
                <ControlBar autoHide={true}></ControlBar>
              </Player>
              <div className="change">更换视频</div>
              <input className="change-file" type="file" accept="video/*" onChange={fileChange}></input>
              <div className="delete" onClick={()=>{
                Dialog.confirm({
                  title: '视频已上传成功，是否删除？',
                  yes: () => {
                    setComplete(0)
                    setVideoPath()
                  }
                });
              }}>
                <img src="resources/community/delete.png" alt="" />
              </div>
            </div>
          </div>
          <NavLink to={(() => {
            let url = URI('https://api3.app.wakaifu.com/criteriaAudit')
                // .addQuery('title', '奖励规则')
                // .addQuery('channel', agent)
                // .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '审核准则'}}
            user={false}
          >
          <div className="rule">
            <img src="resources/community/vodeo_rule.png" alt="" />&nbsp;
            <span>视频审核准则</span>
          </div>
        </NavLink>
        </div> : null}
        <div className="title">
          <input ref={titleRef} defaultValue={draft?.title} value={title} type="text" onChange={(e)=>{
            if (e.target.value.length > 25){
              setTitle(title)
              setTitleLength(title.length)
            }else{
              setTitle(e.target.value)
              setTitleLength(e.target.value.length)
            }
          }} placeholder={(tagid === '1' ? '评论' : tagid === '2' ? '攻略' : '问答') + '标题（选填）'}/>
          <span className='length'>{'('+titleLength + '/25)'}</span>
        </div>
        <div className="content">
          <div >
            <Toolbar
              editor={editor}
              defaultConfig={toolbarConfig}
              mode="default"
              style={{ borderBottom: '0.5px solid #D7D2D2'}}
            />
            <Editor
              contenteditable={true}
              defaultConfig={editorConfig}
              value={html}
              onCreated={setEditor}
              onChange={editor => setHtml(editor.getHtml())}
              mode="default"
              style={{ 
                minHeight: 'calc(100vh - 200px)',
                overflowY: 'hidden'
                }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}