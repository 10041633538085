import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Badge, Footer, List } from 'antd-mobile'
import './css/Clusters.css'
import ClustersItem from './ClustersItem'
import general, { redux } from '../../../../general'
import Dialog from '../../../../lib/Dialog'
import { useStateContext } from '@xkit-yx/im-kit-ui'
import { useLocalStorageState } from 'ahooks'

export default (props) => {
  
  const { store,nim } = useStateContext()
  const [list,setList] = useState([])
  const [loading,setLoading] = useState(false)
  const [showBadge,setShowBadge] = useState(false)
  const [unreadCounts, setUnreadCounts] = useState({});
  const [currentSession, setCurrentSession] = useState(null);
  const [init, setInit] = useState(false);

  const getMyClusters = (showLoading)=>{
    if (loading){
      return
    }
    if (showLoading === true){
      Dialog.loading()
    }
    setLoading(true)
    general.axios.post('/base/yunxin/getMyClusters',{
      "pagination": {
        "page": 1,
        "count": 100
        } 
    })
    .finally(()=>{
      if (showLoading === true){
        Dialog.close()
      }
      setLoading(false)
    })
    .then(res=>{
      const {data,status} = res.data
      if (status.succeed) {
        setList(data.list)
        setInit(true)
        data.list.map(item=>{
          const sessionId = `team-${item.tid}`
          store.sessionStore.sessions.forEach((session,key)=>{
            if (sessionId === key){
              unreadCounts[key] = session['unread']
            }
          })
        })
        setTimeout(() => {
          setUnreadCounts({
            ...unreadCounts
          })
        }, 100);
      }else{
        Dialog.error(status.error_desc)
      }
    })
    .catch((err)=>{
      Dialog.error(err.message);
    })
  }

  const clearMessages = ()=>{
    store.sessionStore.sessions.forEach((session,sessionId)=>{
      clearUnread(sessionId)
    })
  }

  useEffect(()=>{
    if (currentSession){
      let idx = 0
      list.map((item,index)=>{
        if (`team-${item.tid}` === currentSession.sessionId){
          if (item.is_top){
            idx += 1
          }
          item.last_msg = currentSession.body
          list.splice(index,1)
          list.splice(idx,0,item)
          setList([...list])
        }
      })
    }
  },[currentSession])

  useEffect(()=>{
    if (props.active){
      getMyClusters(list.length === 0)
      nim.on('msg',msg=>{
        updateSessions(msg.sessionId)
        setCurrentSession(msg)
      })
    }
  },[props.active])

  useEffect(()=>{
      props.setTitle(
        <div>
          <span style={{
            verticalAlign:'middle'
          }}>我的群聊</span>
          <Badge content={Badge.dot} style={{
              '--top':'10px',minWidth:'6px',minHeight:'6px',
              width:'6px',height:'6px',visibility:showBadge?'visible':'hidden'
            }}>
            <img style={{
              verticalAlign:'middle',marginLeft:'6px',width:'25px'
            }}src="resources/im/clear.png" onClick={clearMessages}/>
          </Badge>
        </div>
      )
  },[showBadge])
  
  const updateSessions = (sessionId)=>{
    store.sessionStore.sessions.forEach((session,key)=>{
      if (sessionId === key) {
        setUnreadCounts({
          ...unreadCounts,
          [sessionId]:session['unread']
        })
      }
    })
  }

  useEffect(()=>{
    let allUnread = 0
    Object.keys(unreadCounts).map((key)=>{
      allUnread += unreadCounts[key]
    })
    redux.dispatch({
      type:'unread',
      data:{
        unread:allUnread
      }
    })
    setShowBadge(allUnread>0)
    // list.map(item=>{
    //   console.log('aaaa',item.tid,getUnRead(`team-${item.tid}`),unreadCounts);
    //   item.unread = getUnRead(`team-${item.tid}`)
    // })
  },[unreadCounts])

  const getUnRead = (tid)=>{
    return unreadCounts[`team-${tid}`]
  }

  const clearUnread = (sessionId)=>{
    store.sessionStore.resetSession(sessionId).then(res=>{
      unreadCounts[sessionId] = undefined
      setUnreadCounts({
        ...unreadCounts,
        [sessionId]:undefined
      })
    })
  }

  const renderCustomTeamSession = (options) => {
    // 对话方account
    // const conversationPartnerId = options.session.to
    // 对话方个人信息（非好友不返回信息， 同步方法）
    // const conversationPartnerInfo = store.uiStore.getFriendWithUserNameCard(conversationPartnerId)
    return 
      <div>
        <div className='' onClick={() => options.onSessionItemClick(options.session)}> 
          {/* <ComplexAvatarContainer caccount={conversationPartnerId}></ComplexAvatarContainer> */}
        
          <div className='nick'>
            {/* {store.uiStore.getAppellation({account: optconversationPartnerId})} */}
          </div>          
          <div className='msg-body'>{options.session.lastMsg.body}</div> 
          <div className='msg-body'>{options.session.unread}</div> 
        </div>
      </div>
  }

  const muteTeamMessage = (tid)=>{
    list.map(item=>{
      if (parseInt(item.tid) === parseInt(tid)){
        item.is_nodisturb = !item.is_nodisturb
        if (item.is_nodisturb){
          clearUnread(`team-${tid}`)
        }
        setList([...list])
      }
    })
  }

  const setTopTeams = (tid)=>{
    list.map(item=>{
      if (parseInt(item.tid) === parseInt(tid)){
        item.is_top = !item.is_top
        setList([...list])
      }
    })
  }

  const expurgateTeams = (tid)=>{
    list.map((item,index)=>{
      if (parseInt(item.tid) === parseInt(tid)){
        clearUnread(`team-${tid}`)
        list.splice(index,1)
        setList([...list])
      }
    })
  }

  return (
    <div className='clusters-index'>

      <div className="clusters-container">
        {list.length === 0 && init ? <div className="empty"><img src="/resources/empty.png" alt=""/>暂无群组</div> : null}
        <List style={{
            '--border-bottom':'none','--border-top':'none'
          }}>
          {list.sort((a,b)=>{
            return b.is_top - a.is_top
          }).map((item,index)=>{
            return <ClustersItem
                    {...props}
                    key={item.tid}
                    item={item}
                    muteTeamMessage={muteTeamMessage}
                    setTopTeams={setTopTeams}
                    expurgateTeams={expurgateTeams}
                    unreadCounts={unreadCounts[`team-${item.tid}`] || ''}
                    clearUnread={clearUnread}
                    ></ClustersItem>
          })}
          <Footer style={{
            display:list.length > 0 ? '' : 'none',
            '--background-color':'#F5F6F8'
          }} label='没有更多了'></Footer>
        </List>
      </div>
    </div>
  )
}
