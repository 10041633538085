import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination} from "swiper";
import {useScroll, useMount, useLockFn} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from '../../../lib/useActiveState';
import {NavLink} from "../../../lib/Navigation";
import Subtitle from "../../components/Subtitle";

SwiperCore.use([Pagination]);

export default ({active, type, setTag}) => {
  const {aplus_queue} = window;
  const [banners, setBanners] = useState([]);
  const [gameGroups, setGameGroups] = useState([]);
  const [obsolete, setObsolete] = useState();
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [infoTextColor, setInfoTextColor] = useState();

  const load = useLockFn(async () => {
    Dialog.loading();
    general.axios.post('/base/game/getGameList', {
      game_classify_type: -1,
      game_species_type: type,
    })
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInfoTextColor(randomColorInfo[parseInt(Math.random() * 4)]);
        setBanners(data.banner_list);
        setGameGroups(data.game_cate);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useMount(() => load());
  useEffect(() => {
    if (active) {
      load({page: 1});
    } else {
      setObsolete(true);
    }
  }, [type]);
  useEffect(() => {
    if (active && obsolete) {
      load({page: 1});
    }
  }, [active, obsolete]);

  const scroll = useScroll();
  useEffect(() => {
    if (active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
    }
  }, [active, scroll]);

  //分类游戏点击埋点
  const clickTheGameOfCategories = (item) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['discover_banner', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), typename: '精选', gamename:item, agent: agent}]
    });
  }

  //精选banner点击埋点
  const clickFeaturedBannerOfGameHall = (item) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['discover_tab', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename:item, agent: agent}]
    });
  }

  return <div className="game-good">
    <div className="banners">
      <Swiper loop={true}
        initialSlide={1} //定位从第几个slider开始轮播
        slidesPerView={'auto'}
        loopedSlides={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{clickable: true}}>
        {banners.map(banner => <SwiperSlide>
          <Link to={'/game?id=' + banner.game_id} className="banner" onClick={()=>clickFeaturedBannerOfGameHall(banner.game_name)}>
            <div className="img"><Image src={banner.image}/></div>
            <div className="entity">
              <div className="icon">
                <Image src={banner.game_image.thumb}/>
              </div>
              <div className="text">
                <div className="name">
                  {/* {banner.game_name} */}
                  <div className={" " + (banner.nameRemark ? " name-new" : "")}>
                    {banner.game_name}
                  </div>
                </div>
                <div className="info1">
                  {banner.game_classify_type}
                  <Subtitle>{banner.nameRemark}</Subtitle>
                  {/* {banner.gama_size.ios_size} */}
                </div>
                <div className="info2">
                  {/* {banner.game_desc.split('+').map(item => <div className="tag">{item}</div>)} */}
                  {/* {banner.game_desc ? banner.game_desc.split('+').map(item => <div
                    className="tag">{item}</div>) : banner.introduction} */}
                </div>
                <div className="info3">
                {/* {banner.introduction ? <span style={{color:'#888',fontSize:'12px'}}>{banner.introduction}</span> : null} */}
              </div>
              </div>
              {banner?.discount < 1 ? <div className="discount" ><span>{banner.discount * 10}</span>折</div> : null}
            </div>
          </Link>
        </SwiperSlide>)}
      </Swiper>
    </div>
    <div className="groups">
      {gameGroups.map(group => <div className="group">
        <div className="head">
          <div className="name">{group.game_classify_name}</div>
          {(()=>{
            if(group.type == 'special') {
              return <NavLink to={"/game/project?id=" + group.specialId} user={false} className="more">更多 {'>'}</NavLink>
            } else if(group.type == 'tag') {
              return <div className="more" onClick={() => setTag(group.game_classify_id)}>更多 {'>'}</div>
            }
          })()}
          {/* <div className="more" onClick={() => setTag(group.game_classify_id)}>更多 ></div> */}
        </div>
        <div className="body clearfix">
          {[group.game_list[0], group.game_list[1], group.game_list[2], group.game_list[3], group.game_list[4], group.game_list[5]].map(game => game  ? <Link to={'/game?id=' + game.game_id} className="item" onClick={()=>clickTheGameOfCategories(game.game_name)}>
            <div className="icon">
              <Image src={game.game_image.thumb}/>
              {game.game_desc.split('+')[1] ? 
              <div>
                <div className="badge">{game.game_desc.split('+')[1]}</div>
              </div>
              : <div className="type_label">{game.discount < 1 ? <div><span>{game.discount * 10}</span>折</div> : ''}</div>}
            </div>
            <div className="name">{game.game_name}</div>
            {/* {
              game.nameRemark ?
              <div className="nameRemark">
                <span>
                {game.nameRemark}
                </span>
              </div>
              : null
            } */}
          </Link> : null)}
        </div>
      </div>)}
    </div>
    {gameGroups.length > 6 ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};