import React, {useEffect, useState} from "react";
import general from "../../general";
import $ from "jquery";
import {useLockFn, useScroll, useUpdateEffect} from "ahooks";
import Dialog from "../../lib/Dialog";
import Operate from "./Operate";
import Image from "../../lib/Image";
import moment from "moment";
import {Link} from "react-router-dom";

export default props => {
  const [messagenew, setMessagenew] = useState([]);

  useEffect(() => {
    setMessagenew(general.messagenew);
    // console.log(general.messagenew.title);
    // console.log(messagenew.title);
    props.setTitle(<span>{general.messagenew.title}</span>);
  }, [props.active]);

//   console.log(messagenew.title);

//   useUpdateEffect(() => {
//     //   console.log(messagenew);
//     if (props.active && messagenew) {
//         props.setTitle(<span style="">{messagenew.title}</span>);
//     }
//   }, [props.active, messagenew]);

  return <div className="message-new">
       <div className="message-new-halo width">
            <div className="background" />
            {messagenew.content && <div className="container" dangerouslySetInnerHTML={{__html:messagenew.content.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}></div>}
        </div>
    
  </div>;
};