import {useState} from "react";
import {Link} from "react-router-dom";
import {useMount, useInterval, useLockFn} from "ahooks";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import useActiveState from "../../lib/useActiveState";
import moment from "moment";

export default props => {
  const [agent] = useActiveState('agent');
  const [activeEndTime, setActiveEndTime] = useState(1680019199);//终止时间
  const [leftTime, setLeftTime] = useState(0);//时间间隔

  const [data, setData] = useState({});
  const [recordRows, setRecordRows] = useState([]); //好友明细
  const [surplusSeconds, setSurplusSeconds] =  useState(0);//时间
  const [desc, setDesc] = useState([]);//活动说明
  const [init, setInit] = useState(false); //标识

  useMount(() => {
    props.setOperate(<Link to="/invite/newrecord" className="invite-index-record">提现明细</Link>);

    general.axios.post('/invite/v2/index?test=1')
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);
        setRecordRows(data.recordRows);
        setSurplusSeconds(data.surplusSeconds);

        if(data.desc) {
          let desc = data.desc.split('\r\n');
          setDesc(desc);
        }

        setData(data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  console.log(moment().format("YYYYMMDD"));

  //倒计时
  useInterval(() => {
    // setLeftTime(activeEndTime - Math.round(new Date().getTime() / 1000).toString());

    if(surplusSeconds) {
      setSurplusSeconds(surplusSeconds-1);
    }
  }, 1000);

  const [state, setState] = useState(false);

  const receive = useLockFn(async (parms = {}) => {
    general.axios.post('/invite/v2/receive')
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setState(true);
        Dialog.info('领取成功');
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  return <div className="new-invite-index">
    <div className="makemoney-main">
      <img src="/resources/invite/top.png" alt=""/>
      <div className="makemoner-content">
        {
          recordRows.length >= 5 ? 
            state ? 
            <div className="icon-operate" ><img src="/resources/invite/alreadyreceived.png" /></div>
            :
            <div className="icon-operate receiveaward" onClick={() => receive()}><img src="/resources/invite/receiveaward.png" /></div>
          :
            <CopyToClipboard text={'好友邀请您组团白嫖！畅玩福利手游，不花冤枉钱！' + data.url} onCopy={() => Dialog.info('已复制邀请链接')}>
              <div className="icon-operate"><img src="/resources/invite/makemoney.png" /></div>
            </CopyToClipboard>
        }

        {/* <CopyToClipboard text={data.url} onCopy={() => Dialog.info('已复制邀请链接')}>
          <div className="icon-operate"><img src="/resources/invite/makemoney.png" /></div>
        </CopyToClipboard> */}
        <div className="countdown">
          本轮奖励倒计时 <span className="hour">{Math.floor(surplusSeconds / 3600) < 10 ? '0' + Math.floor(surplusSeconds / 3600) : Math.floor(surplusSeconds / 3600)}</span> : <span className="minute">{Math.floor(surplusSeconds % 3600 / 60) < 10 ? '0' + Math.floor(surplusSeconds % 3600 / 60) : Math.floor(surplusSeconds % 3600 / 60)}</span> : <span className="seconds">{Math.floor(surplusSeconds % 60) < 10 ? '0' + Math.floor(surplusSeconds % 60) : Math.floor(surplusSeconds % 60)}</span>
        </div>
      </div>
    </div>
    <div className="glist">
      <div className="particulars">
        <div className=""><img src="/resources/invite/particulars_top.png" /></div>
        <div className="friend-record" style={{backgroundImage: 'url(/resources/invite/contbg.png)'}}>
          {recordRows.length > 0 ? 
          <>
          <div className="thead">
            <div className="th">好友账号</div>
            <div className="th">注册时间</div>
          </div>
          <div className="tbody">
            {
              recordRows?.map((item, index) => <div className="tr">
                <div className="td">{item.username}</div>
                <div className="td">{item.regDate}</div>
              </div>)
            }
          </div></>
          : null}

          {recordRows.length < 1 && init ? <div className="friend-empty">
            暂无记录
          </div> : null}
        </div>
        <div className="friend-footer"><img src="/resources/invite/particulars_foot.png" /></div>
      </div>
      <div className="description">
        <div className=""><img src="/resources/invite/description_top.png" /></div>
        <div className="content" style={{backgroundImage: 'url(/resources/invite/contbg.png)'}}>

          {desc?.map(item => <p>
            {item}
          </p>)}
        </div>
        <div className=""><img src="/resources/invite/particulars_foot.png" /></div>
      </div>

      {
        data?.status !== 1 ? 
        <div className="receiveaward" onClick={() => receive()}><img src="/resources/invite/receiveaward.png" /></div>
        : null
      }
    </div>
  </div>;
};