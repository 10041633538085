import ReactDOM from "react-dom";
import $ from "jquery";
import {CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {v4 as uuidv4} from 'uuid';
import general from "../general";

let dialogs, setDialogs;

const DialogItem = ({dialog, index, dialogs}) => {
  useEffect(() => {
    if (dialog.duration) {
      setTimeout(() => {
        Dialog.close(index);
      }, dialog.duration * 1000);
    }
    if (dialog.onClose) {
      return () => {
        dialog.onClose();
      }
    }
  }, []);

  const getBackgroundColor = ()=>{
    if (typeof(dialog.icon) === 'object'){
      return 'rgba(0,0,0,.75)'
    }else{
      if (dialog.icon === 'agree' || dialog.icon === 'loading'){
        return "transparent"
      }else{
        return 'rgba(0,0,0,.75)'
      }
    }
  }

  return <div className={'dialog' + (index === dialogs.length - 1 ? ' active' : '')}>
    {dialog.mask ? <div className="dialog-mask" style={dialog.maskOpacity ? {opacity: dialog.maskOpacity} : {}}/> : null}
    <div className="dialog-content">
      {dialog.type === 'toast' ? <div className="dialog-toast" style={{backgroundColor:getBackgroundColor()}}>
        {typeof(dialog.icon) === "string" && dialog.icon.length > 0 ? 
        <div className="toast-icon">
          <img style={{width:'100px',height:'100px'}} src={(()=>{
            if (dialog.icon === 'agree'){
              return "resources/community/agree.gif"
            }else if(dialog.icon === 'loading'){
              return "resources/loading.gif"
            }
          })()}></img>
        </div> : <div className="toast-icon">{dialog.icon}</div>}
        {dialog.content ? <div className="toast-text">{dialog.content}</div> : null}
      </div> : <div className="dialog-modal">
        <div className="modal-body">
          {dialog.title ? <div className="modal-title" dangerouslySetInnerHTML={{__html:dialog.title.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r)/g, '$1<br>$2')}}>{}</div> : null}
          {dialog.content ? <div className="modal-content">{dialog.content}</div> : null}
        </div>
        {dialog.btns.length > 0 ? <div className={'modal-btns' + (dialog.btns.length > 2 ? ' column' : '') + ` ${dialog.btns[0].style}`}>
          {dialog.btns.map(btn => <div className={'modal-btn ' + btn.style} onClick={() => {
            btn.onPress && btn.onPress();
            Dialog.close(index);
          }}>{btn.text}</div>)}
        </div> : null}
      </div>}
    </div>
  </div>;
}

const DialogRender = () => {
  [dialogs, setDialogs] = useState([]);
  return <TransitionGroup>
    {dialogs.map((dialog, index, dialogs) => <CSSTransition key={dialog.id} timeout={250}>
      <DialogItem {...{dialog, index, dialogs}}/>
    </CSSTransition>)}
  </TransitionGroup>;
}

(() => {
  if ($('.dialogs').length === 0) {
    $('body').append('<div class="dialogs"></div>');
  }

  if ($('.toast-mask').length === 0) {
    ReactDOM.render(<DialogRender/>, $('.dialogs')[0]);
  }
})();

const Dialog = {};

Dialog.dialog = (dialog = {btns: []}) => {
  setDialogs(dialogs => [...dialogs, ({...dialog, id: uuidv4()})]);
}

Dialog.toast = ({icon, content, duration, onClose, mask}) => {
  Dialog.dialog({type: 'toast', duration, icon, content, onClose, mask, maskOpacity: .05});
}

Dialog.success = (content, onClose) => {
  Dialog.toast({
    icon: <CheckCircleOutlined/>,
    content: content,
    duration: 1,
    mask: false,
    onClose,
  });
}

Dialog.error = (content, onClose) => {
  Dialog.toast({
    icon: <CloseCircleOutlined/>,
    content: content,
    duration: 1,
    mask: false,
    onClose,
  });
}

Dialog.info = (content, onClose) => {
  Dialog.toast({
    icon:'',
    content: content,
    duration: 1,
    mask: false,
    onClose,
  });
}

Dialog.loading = content => {
  Dialog.toast({
    // icon: <LoadingOutlined/>,
    icon:'loading',
    content: content,
    duration: 0,
    mask: true,
  });
}

Dialog.offline = content => {
  Dialog.toast({
    icon: <CloseCircleOutlined/>,
    content: content,
    duration: 1,
    mask: false,
  });
}

Dialog.alert = (title, content, onClose, param = {}) => {
  Dialog.dialog({
    type: 'modal',
    mask: true,
    title: title,
    content,
    btns: [
      {
        text: param.btn || '确定',
        onPress: onClose,
        style: param.style || 'success',
      }
    ],
  });
}

Dialog.confirm = ({title, yes, no}) => {
  Dialog.dialog({
    type: 'modal',
    mask: true,
    title,
    btns: [
      {
        text: '取消',
        onPress: no,
      },
      {
        text: '确定',
        onPress: yes,
      },
    ]
  });
}

Dialog.close = param => {
  setDialogs(dialogs => {
    if (param === true) {
      return [];
    } else if (dialogs[param]) {
      dialogs.splice(param, 1);
    } else {
      dialogs.pop();
    }
    return [...dialogs];
  });
}

export default Dialog;