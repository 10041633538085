import React, {useEffect, useState} from "react";
import {useLockFn, useScroll} from "ahooks";
import general from "../../general";
import $ from "jquery";
import moment from "moment";
import Dialog from "../../lib/Dialog";

export default ({active}) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let data = {
      pagination: {page: page},
    };
    if (parms.page) {
      data.pagination.page = parms.page;
    }
    general.axios.post('/user/pay/getRechargeList', data)
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          let {status, data} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setInit(true);
          if (parms.page) {
            setPage(parms.page);
          }
          let {paginated, pay_list: list} = data;
          setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
          setMore(paginated.more && list.length > 0);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useEffect(() => {
    if (active && !init) {
      load();
    }
  }, [active]);

  const scroll = useScroll();
  useEffect(() => {
    if (active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, active]);

  return <div className="ptb-record">
    {datas.map(data => <div className="item">
      <div className="time">{moment(data.recharge_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
      <div className="amount">+{data.recharge_coin}</div>
    </div>)}
    {datas.length === 0 && init ? <div className="empty"><img src="/resources/empty.png" alt=""/>暂无数据</div> : null}
  </div>;
};