import {useEffect, useState} from "react";
import {useLockFn, useMount} from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import {useHistory, useLocation} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import URI from "urijs";
import Image from "../../lib/Image";
import {NavLink} from "../../lib/Navigation";
import MobileDetect from "mobile-detect";
import moment from "moment";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";

export default props => {
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const location = useLocation();
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  const [agent] = useActiveState('agent');
  const mobileDetect = new MobileDetect(navigator.userAgent);


  return <div className="save-vip-descript">
    <div className="profile">
      <div className="title">
        <div className="icon"><img src="/resources/save/descript-tips1.png" /></div>
        <div className="name">会员简介</div>
      </div>
      <div className="info">
        本平台会员分为3个等级，月卡会员、季卡会员和年卡会员。<br/>
        每个用户可根据自己的需求选择购买不同等级的会员，等级越高，享受的权益越多。
      </div>

      <div className="des">
        具体权益说明如下：
      </div>

      <div className="tables-brief">
        <div className="brief-title">
          <img src="/resources/save/descript-title.png" />
        </div>
        <div className="">
          <img src="/resources/save/descript-table-1.png" />
        </div>
      </div>

      <div className="title">
        <div className="icon"><img src="/resources/save/descript-tips2.png" /></div>
        <div className="name">温馨提示</div>
      </div>

      <div className="hint">
        会员可叠加购买，但<span>有效期内再次购买会员会导致原先的会员等级、时长及相应权益被覆盖失效</span>，请谨慎操作，若因叠加购买导致的权益降级和损失，本平台有权不予补偿和退款。
      </div>
    </div>
  </div>;
};