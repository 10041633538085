import { useEffect, useState } from "react";
import general from "../../../general";
import { useReactive } from "ahooks";
import moment from "moment";
import { Link } from "react-router-dom";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import { AndroidOutlined, AppleOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import '../../../css/gameTrade.css';
import CzjlItem from "../../components/CzjlItem";

export default props => {
  const data = useReactive({
    active: props.active,
  });
  useEffect(() => {
    data.active = props.active;
  }, [props]);
  const [trades, setTrades] = useState([]);
  const [more, setMore] = useState();
  const load = (parms = {}) => {
    if (data.loading) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    general.axios.post('/user/trade/getTradeList', {
      member_id: undefined,
      username: undefined,
      trade_type: '3',
      game_id: props.query.id,
      pagination: { count: 100, page: 1 }
    })
      .finally(() => {
        data.loading = false;
        Dialog.close();
      })
      .then(res => {
        data.init = true;
        let result = res.data.data;
        setTrades(result.account_list);
        setMore(result.paginated.more);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  useEffect(() => {
    if (data.active && !data.init) {
      load();
    }
  }, [data.active]);

  return (
    <div className="game-trade">
      <img className='top-icon' src="/resources/game/trade-banner.png" alt="" />

      {trades.map(trade =>
        <CzjlItem>{trade}</CzjlItem>
        )}
      {trades.length === 0 && data.init ? <div className="recovery-empty"><img src="/resources/game/detail-trade-place.png" alt="" />暂无交易</div> : null}
      {trades.length > 0 && data.init && more == 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    </div>
  );
};