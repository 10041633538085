import React, {useEffect, useState} from "react";
import {NavLink} from "../../../lib/Navigation";
import general from "../../../general";
import Dialog from "../../../lib/Dialog";
import useActiveState from "../../../lib/useActiveState";
import {useHistory, useLocation} from "react-router-dom";
import URI from "urijs";

const top_task = [
    {
      name: '6元无门槛券',
      info: '游戏充值直接抵扣6元  畅玩无忧',
      type: 6,
      class: '',
      taskname: 'balance'
    },
];

const tasks = [
    {
      name: '签到',
      reward: '10元代金券 满30元可用',
      type: 7,
      class: 'sign',
      taskname: 'sign'
    },
    {
      name: '登录游戏',
      reward: '20元代金券 满98元可用',
      type: 8,
      info: '给自己取个响亮的名字吧',
      class: 'login',
      taskname: 'login_game'
    },
    {
      name: '累计充值10元',
      reward: '30元代金券 满198元可用',
      type: 9,
      class: 'reach',
      taskname: 'recharge'
    },
];

export default props => {
    const location = useLocation();
    const history = useHistory();
  const [data, setData] = useState();
  const [datas, setDatas] = useState([]);
//   useEffect(() => {
//       !data && setData(datas.day3_info);
//   }, [datas]);

  const load = () => {
    if(!general.isLogin()) {
        return false;
    }
    Dialog.loading();
    general.axios.post('/user/active2203/getIndexData')
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setDatas(data);
        setData(data.day3_info);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    }

    useEffect(() => {
        if (props.active){
            load();
        }
    }, [props.active]);

  const receive_three = (type)=>{
    if(!general.isLogin()) {
        history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
        return false;
    }
    let name = type.taskname;
    if(data[name] == 1) {
        if(type.taskname == 'balance') {
            if(datas.day < 3) {
                Dialog.info("活动还未开启");
                return false;
            }
        }
        Dialog.loading();
        general.axios.post('/user/active2203/receive', {type:type.type})
        .finally(() => {
            Dialog.close();
        })
        .then(res => {
            let {status, data} = res.data;
            
            if (!status.succeed) {
                Dialog.info(status.error_desc);
            } else {
                Dialog.info("领取成功");
                load();
            }
        })
        .catch(err => {
            Dialog.error(err.message);
        });
    } else if(data[name] == 0) {
        if(datas.day < 3) {
            Dialog.info('活动未开启');
        } else if (type.taskname === 'sign') {
            general.history.push('/task/signdata');
        } else if (type.taskname === 'login_game') {
            Dialog.alert('温馨提示', '畅玩任何一款游戏均可领取金币哦');
        } else if (type.taskname === 'recharge') {
            Dialog.info("仅计算现金/平台币充值游戏");
        }
    }
}

  return <div className="three-day">
    <div className="width">
      <div className="three-card">
        <h1>
        6元首充限时送
        </h1>
        {top_task.map(task => <div className="card-index " style={{backgroundImage: 'url(/resources/party/two-card.png)'}} onClick={() => receive_three(task)}>
            <div className="text">
                <div className="name">{task.name}</div>
                <div className="desc">{task.info}</div>
            </div>
            <div className='but'>
                {data ? <>
                {(()=>{
                    let name = task.taskname;
                    if(datas.day < 3) {
                        return <img src="/resources/party/two-no-start.png" />
                    } else if(data[name] == 0) {
                        return '去完成';
                    } else if(data[name] == 1) {
                        return <img src="/resources/party/two-lq.png"/>;
                    } else if(data[name] == 2) {
                        return  <img src="/resources/party/two-already.png" />;
                    }
                })()}</>
                : <img src="/resources/party/two-lq.png"/>}
            </div>
        </div>)}
    </div>

    <div className="three-task">
        <h1>
        做任务 抵现金
        </h1>
        <div className="tasks">
            {tasks.map(task => <div className={"task " + task.class } onClick={() => receive_three(task)}>
                <div className="icon"><img src={'/resources/party/two-' + (task.class) + '.png'} alt=""/></div>
                <div className="info">
                    <div className="name">
                    {task.name}
                    </div>
                    <div className="desc">{task.reward}</div>
                </div>
                <div className={'operate ' + (datas.day < 3 ? 'no-open' : ( data ? data[task.taskname] == 2 ? 'alreadly-lq' : ''  : '' ))}>
                    {data ? <>
                    {(()=>{
                        let name = task.taskname;
                        if(datas.day < 3) {
                            return '未开启';
                        } else if(data[name] == 0) {
                            return '去完成';
                        } else if(data[name] == 1) {
                            return '领取';
                        } else if(data[name] == 2) {
                            return  '已领取';
                        }
                    })()}</>
                    : '去完成'}
                </div>
            </div>)}
        </div>
      </div>
    </div>
    
  </div>;
};