import {useHistory} from "react-router-dom";
import React, {useState} from "react";
import general from "../../general";
import {CloseOutlined} from "@ant-design/icons";
import {useLockFn} from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [data, setData] = useState(user);
  const [focus, setFocus] = useState('');
  const save = useLockFn(async () => {
    Dialog.loading();
    await general.axios.post('/user/user/editMemberInfo', {qq: data.qq})
        .finally(() => {
          Dialog.close();
        })
        .then(response => {
          let {status} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setUser({...user, ...data});
            Dialog.success('保存成功', () => history.goBack());
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });


  const handleKeyPress = (event) => {
    const invalidChars = ['-', '+', 'e', '.', 'E']
    if(invalidChars.indexOf(event.key) !== -1){
      event.preventDefault()
    }
  }

  return <div className="user-qq">
    <div className="user-input">
      <div className="name">QQ</div>
      <input name="qq" type="number" value={data.qq} placeholder="请输入QQ" onKeyPress={handleKeyPress} autoComplete="off" onFocus={() => setFocus('qq')} onChange={e => setData({...data, qq: e.target.value})}/>
      {focus === 'qq' && data.qq ? <CloseOutlined className="close" onClick={() => setData({...data, qq: ''})}/> : null}
    </div>
    <div className="user-btn" onClick={save}>保存</div>
  </div>;
};