import List from "./List";
import React from "react";
import useActiveState from "../../lib/useActiveState";

export default props => {
  const [config] = useActiveState('config');
  
  return <div className="trade-good">
    {/* <div className="good-img"><img src="/resources/trade/goods.png" alt=""/></div> */}
    <div className="good-img"><img src={config.trade_goods_cover} alt=""/></div>
    <List active={props.active} good={true} source={props.query.source} handleTitle={()=>{}}/>
  </div>;
};