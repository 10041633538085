import React, { useState, useEffect } from 'react'
import { Popup } from 'antd-mobile'
import { useLocalStorageState } from 'ahooks'
import { CloseOutlined } from '@ant-design/icons'
import './css/mask.css'

export default function Mask(props) {

  const [time,setTime] = useLocalStorageState('recovery',0)
  const [visible,setVisible] = useState(Date.parse(new Date()) > time)
  const [selected,setSelected] = useState(false)

  return (
    <div>
      <Popup className="recovery-mask-index"
        visible={visible}
        stopPropagation={['click']}
        bodyStyle={{
          overflow: 'scroll',
          borderTopLeftRadius:'13px',
          borderTopRightRadius:'13px',
          padding:'15px',
          backgroundColor:'white',
          maxHeight:'80vh',
        }}>
        <div className="recovery-mask-container">
            <div className="recovery-tips">
              <div className="title">回收须知</div>
              <div className="desc">
                {
                  props.notice?.map((item, index) => <div className="item">
                  {item}
                </div>)
                }
              </div>
              <CloseOutlined className="recovery-close" onClick={()=>setVisible(false)}></CloseOutlined>
            </div>
            <div className="recovery-tips">
              <div className="title">温馨提示</div>
              <div className="desc active">
                {props.waring}
                {/* <div className="item">赎回后，可直接选择相应的小号登陆游戏！</div> */}
              </div>
            </div>
            <div className="recovery-know" onClick={()=>setVisible(false)}>我知道了</div>
            <div className="recovery-hidden" onClick={()=>{
              setSelected(e=>{
                console.log(e)
                if (!e) {
                  const date = Date.parse(new Date())
                  const future = date + 3*24*3600*1000
                  setTime(future)
                }else {
                  setTime(0)
                }
                return !e
              })
            }}>
              <img src={"resources/hangup/" + (selected ? 'select' : 'default') + '.png'} alt="" />
              <span>3天内不再显示</span>
            </div>
        </div>
      </Popup>
    </div>
  )
}
