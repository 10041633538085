import React, {useEffect, useState} from "react";
import general from "../../general";
import $ from "jquery";
import {useLockFn, useScroll, useThrottleEffect} from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

export default props => {
  const [user, setUser] = useActiveState('user');
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      search_content: props.kw,
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/user/integralShop/platformCurrencyList', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setInit(true);
          if (parms.page) {
            setPage(parms.page);
          }
          let {ptbList: list, paginated} = data;
          setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
          setMore(paginated.more && list.length > 0);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useThrottleEffect(() => {
    if (init) {
      $('html,body').scrollTop(0);
      load({page: 1});
    }
  }, [props.kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  const exchange = useLockFn(async data => {
    Dialog.loading();
    await general.axios.post('/user/integralShop/exchangePtb', {g_id: data.g_id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setUser({...user, integral: user.integral - data.goods_price});
            Dialog.success('兑换成功');
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  return <div className="credit-ptb">
    {datas.map(data => <div className="item" onClick={() => exchange(data)}>
      <div className="icon"><img src="/resources/credit/box.png"/></div>
      <div className="info">
        <div className="ptb">{data.ptb_num}平台币</div>
        <div className="credit">{data.goods_price}积分</div>
      </div>
      <div className="btn">立即兑换</div>
    </div>)}
  </div>;
};