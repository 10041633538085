import {useEffect, useRef, useState, useCallback} from "react";
import Video from "./Video";

export default props => {
    const [routerIndex, setRouterIndex] = useState(parseInt(props) || 0);
    const [opacity, setOpacity] = useState();
    const [operate, setOperate] = useState(null);

    return <Video active={true} 
        setOpacity={opacity}
        operate={operate}
        setOperate={setOperate}
    />
}