import React, { useEffect } from 'react'
import './css/components.css';

export default function Subtitle(props) {
  return (
    <span style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>
      {props.children && <span className='subtitle'>{props.game?.game_size.ios_size != '0 MB' ? ' · ' : ''}{props.children}</span>}  
    </span>
  )
}
