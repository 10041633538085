import React, { useState, useEffect } from 'react'
import { Popup, Image } from 'antd-mobile'
import useActiveState from '../../lib/useActiveState'
import Dialog from '../../lib/Dialog'
import './css/Mask.css'

export default function VersionMask() {

  const [app] = useActiveState('app');
  const [index,setIndex] = useState(0)
  const [visible,setVisible] = useState(true)

  useEffect(() => {
    if (index == 0) {
      setTimeout(() => {
        setIndex(1)
      }, 3000);
    }else if (index == 1) {
      setTimeout(() => {
        setIndex(2)
      }, 4000);
    }else {
      setTimeout(() => {
        setIndex(0)
      }, 6000);
    }
    
  }, [index])

  return (
    <div>
      <Popup className="version-mask-index"
        visible={visible}
        stopPropagation={['click']}
        bodyStyle={{
          borderRadius: '13px',
          overflow: 'hidden',
          margin: '15px',
          width: 'calc(100% - 30px)'
        }}>
        <div className="version-mask-container">
          <Image src={`resources/mask/version-${index+1}.gif`}/>
          <div className="index">
            第{index+1}步
          </div>
        </div>
        <div className="version-mask-bottom">
          <div className="mask-bottom-content">
            <div className="mask-bottom-content-desc" style={{fontWeight:'500'}}>
            请先按照以上提示安装描述文件后，即可下载游戏！
            </div>
            <div className="mask-bottom-content-desc" style={{color:'red'}}>
            因苹果系统限制，如遇到系统安全延迟一小时提示，请等待延迟结束后放心安装使用！
            </div>
          </div>
          <div className="mask-bottom-copy-action" onClick={()=>setVisible(false)}>我知道了</div>
        </div>
      </Popup>
    </div>
  )
}
