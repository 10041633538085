import React, { useEffect, useState } from 'react'
import { List, SwipeAction, Avatar } from 'antd-mobile'
import { Action, SwipeActionRef } from 'antd-mobile/es/components/swipe-action'
import { NavLink } from '../../../../lib/Navigation'
import general from '../../../../general'
import Dialog from '../../../../lib/Dialog'

export default function ClustersItem(props) {

  const item = props.item

  const rightActions:Action[] = [
    {
      key: 'unsubscribe',
      text: item.is_nodisturb === true ? '取消免打扰' : `免打扰`,
      color: '#CCCCCC',
      onClick: async () => {
        const params = {
          tid:item.tid,
          ope:item.is_nodisturb === true ? 2 : 1
        }
        general.axios.post('/base/yunxin/muteTeamMessage',params)
        .then(res=>{
          const {data,status} = res.data
          if (status.succeed){
            if (props.muteTeamMessage){
              props.muteTeamMessage(item.tid)
            }
          }else{
            Dialog.error(status.error_desc)
          }
        })
      }
    },
    {
      key: 'mute',
      text: item.is_top === true ? '取消置顶' : '置顶',
      color: '#4E94FE',
      onClick: async () => {
        const params = {
          tid:item.tid,
          ope:item.is_top === true ? 2 : 1
        }
        general.axios.post('/base/yunxin/setTopTeams',params)
        .then(res=>{
          const {data,status} = res.data
          if (status.succeed){
            if (props.setTopTeams){
              props.setTopTeams(item.tid)
            }
          }else{
            Dialog.error(status.error_desc)
          }
        })
      }
    },
    {
      key: 'delete',
      text: '删除',
      color: '#FF596A',
      onClick: async () => {
        const params = {
          tid:item.tid
        }
        if (props.expurgateTeams){
          props.expurgateTeams(item.tid)
        }
        // general.axios.post('/base/yunxin/expurgateTeams',params)
        // .then(res=>{
        //   const {data,status} = res.data
        //   if (status.succeed){
        //     if (props.expurgateTeams){
        //       props.expurgateTeams(item.tid)
        //     }
        //   }else{
        //     Dialog.error(status.error_desc)
        //   }
        // })
      }
    },
  ]

  return (
    <SwipeAction leftActions={[]} rightActions={rightActions} style={{'--background':'#F5F6F8'}}>
      <NavLink to={"/chat?tid=" + item.tid} onClick={()=>props.clearUnread(`team-${item.tid}`)} key={props.tid}>
        <List.Item clickable={false} style={{
            '--border-inner':'none'
          }}>
        <div className="item">
          <div className="avatar">
            <Avatar style={{
              '--size':'53px','--border-radius':'26px'
            }} src={item.icon}></Avatar>
            <img className='icon' src="resources/im/guanfang.png"/>
          </div>
          <div className="content">
            <div className="tname">{item.tname}</div>
            <div className="message">{item.last_msg}</div>
          </div>
          {item.is_nodisturb ? 
          <img width={15} src='resources/im/miandarao.png'></img> :
          <div className="count" style={{
            backgroundColor:item.is_nodisturb ? '#CCCCCC' : '#F90751',
            display:props.unreadCounts === 0 ? 'none' : '',
            width:props.unreadCounts < 10 ? '21px' : 'auto'
          }}>{props.unreadCounts > 99 ? '99+' : props.unreadCounts}</div>}
          <div className='top_icon' style={{
              zIndex:(item.is_top === true ? '0' : '-1')
            }}>
            <img src="resources/im/top.png"  />
          </div>
        </div>
        </List.Item>
      </NavLink>
    </SwipeAction>
  )
}
