import {NavLink} from "../../lib/Navigation";
import URI from "urijs";
import {Link} from "react-router-dom";
import MobileDetect from "mobile-detect";
import useActiveState from "../../lib/useActiveState";
var packageJson = require('../../../package.json');

const mobileDetect = new MobileDetect(navigator.userAgent);
export default props => {
    const [agent] = useActiveState('agent');
    const [config] = useActiveState('config');
    return <div className="user-set">
        <div className="set-list">
            <dl className="">
                <NavLink to="/feedback">
                <dt>
                    <div className="full">
                    <div className="icon" style={{backgroundImage: 'url(/resources/user/set1.png)'}}/>
                    <div className="text">投诉反馈</div>
                    </div>
                    <div className="down "><img src="/resources/welfare/left-back.png" /></div>
                    
                </dt>
                </NavLink>
                <NavLink to={(() => {
                    let url = URI('//api3.app.wakaifu.com/respons')
                        .addQuery('title', '用户协议')
                        .addQuery('channel', agent)
                        .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                        .toString();
                    return url;
                    })()} iframe={{title: '免责声明'}} user={false}>
                <dt>
                    <div className="full">
                    <div className="icon" style={{backgroundImage: 'url(/resources/user/set2.png)'}}/>
                    <div className="text">免责声明</div>
                    </div>
                    <div className="down "><img src="/resources/welfare/left-back.png" /></div>
                </dt>
                </NavLink>
                <NavLink to={(() => {
                    let url = URI('//api3.app.wakaifu.com/userAgreement')
                        .addQuery('title', '用户协议')
                        .addQuery('channel', agent)
                        .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                        .toString();
                    return url;
                    })()} iframe={{title: '用户协议'}} user={false}>
                <dt>
                    <div className="full">
                    <div className="icon" style={{backgroundImage: 'url(/resources/user/set3.png)'}}/>
                    <div className="text">用户协议</div>
                    </div>
                    <div className="down "><img src="/resources/welfare/left-back.png" /></div>
                </dt>
                </NavLink>
                <NavLink to={(() => {
                    let url = URI('//api3.app.wakaifu.com/privacyPolicy')
                        .addQuery('title', '隐私政策')
                        .addQuery('channel', agent)
                        .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                        .toString();
                    return url;
                    })()} iframe={{title: '隐私政策'}} user={false}>
                <dt>
                    <div className="full">
                    <div className="icon" style={{backgroundImage: 'url(/resources/user/set4.png)'}}/>
                    <div className="text">隐私政策</div>
                    </div>
                    <div className="down "><img src="/resources/welfare/left-back.png" /></div>
                </dt>
                </NavLink>
            </dl>
      </div>
      <div style={{margin:'0 auto',color:'#bbb',fontSize:'13px'}}>版本号:{packageJson.version}</div>
        {config.filingCode ? <div className="archival">
          <div className="desc" onClick={()=>{
            window.open('https://beian.miit.gov.cn/',"_black")
          }}>
            APP备案码：{config.filingCode}
          </div>
        </div> : null}
      
    </div>
}