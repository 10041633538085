import React, { useEffect, useState } from 'react'
import './css/question.css'
import general from '../../../../general'
import Dialog from '../../../../lib/Dialog'
import { Collapse, DotLoading, Result } from 'antd-mobile'
import { NavLink } from '../../../../lib/Navigation'
import { Link } from "react-router-dom";

export default function HangupQuestion(props) {

  const [list,setList] = useState([])
  const [openArray,setOpenArray] = useState([])
  const [index,setIndex] = useState(-1)

  const getCloudMachineFqa = ()=>{
    Dialog.loading()
    general.axios.post('getCloudMachineFqa')
    .finally(()=>{
      Dialog.close()
    })
    .then(res=>{
       let {status, data} = res.data;
       if (!status.succeed) {
         Dialog.error(status.error_desc);
         return;
       }
       setList(data)
     })
  }

  useEffect(()=>{
    if (props.active && list.length == 0){
      getCloudMachineFqa()
      props.setOperate(<NavLink to='/hangupfeedback'>产品反馈</NavLink>)
    }
  },[props.active])

  return (
    <div className='hangup-question-index'>
      <div className="hangup-question-container">
        <Collapse accordion={true} onChange={e=>{
          setOpenArray(e)
        }}>
          {list.map((item,idx)=>
            <Collapse.Panel onClick={()=>setIndex(idx)} className={'hangup-question-item ' + (openArray && openArray.includes(idx.toString()) ? "item-border" : "item-border-none")} key={idx} title={
              <div className='hangup-question-item-header'>
                <img className='icon' src='resources/hangup/wen.png'></img>
                <span className='title'>{item.q}</span>
              </div>
            }>
              <div className='hangup-question-item-content'>
                {/* <img className='icon' src="resources/hangup/da.png" alt="" /> */}
                <span dangerouslySetInnerHTML={{__html: item.a.replace(/([^>\n]?)(\n)/g, '$1<br>$2')}} className='title'></span>
              </div>
            </Collapse.Panel>
          )}
        </Collapse>
        <Link style={{display:list.length == 0 ? 'none' : ''}} to="/service" className="service">联系客服</Link>        
      </div>
    </div>
  )
}
