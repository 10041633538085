import { Provider } from '@xkit-yx/im-kit-ui'
import React, { useEffect, useState } from 'react'
import App from './App'
import useActiveState from './lib/useActiveState'
import PubSub from 'pubsub-js'
import IMAppContainer from './IMAppContainer'

export default function IMApp() {

  const [user] = useActiveState('user')
  const [init,setInit] = useState(false)
  const [initOptions,setInitOption] = useState({})
  const [login,setLogin] = useState(false)

  useEffect(()=>{
    PubSub.subscribe('loginSubscribe',(name,isLogin)=>{
      if (isLogin){
        setInitOption({
          appkey:'e743d85abdd4ef60e96c75d6c1909cec',
          account:localStorage.getItem('yx_account'),
          token:localStorage.getItem('yx_token'),
          reconnectionAttempts:99999,
          debugLevel:'off'
        })
      }
      setLogin(isLogin)
      setInit(true)
    })

    if (localStorage.getItem('yx_token') && localStorage.getItem('yx_login')){
      setInitOption({
        appkey:'e743d85abdd4ef60e96c75d6c1909cec',
        account:localStorage.getItem('yx_account'),
        token:localStorage.getItem('yx_token'),
        debugLevel:'off',
      })
      setLogin(true)
      setInit(true)
    }

  },[])

  return <>
    {login && init ? 
    <Provider 
      initOptions={initOptions}
      sdkVersion={1}
      renderImConnecting={()=><IMAppContainer/>}
      renderImDisConnected={()=><IMAppContainer/>}
      renderImIdle={()=><IMAppContainer/>}
    >
      <IMAppContainer/>
    </Provider> :
    <App/>}
  </>
}
