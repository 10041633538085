import {Link, useHistory, useLocation} from "react-router-dom";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {RightOutlined} from '@ant-design/icons';
import {useLocalStorageState, useMount} from "ahooks";
import { useState } from "react";
import MobileDetect from "mobile-detect";
import URI from "urijs";
import {NavLink} from "../../lib/Navigation";
import { useEffect } from "react";
import PubSub from "pubsub-js";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default props => {
  const history = useHistory();
  const location = useLocation();
  let [agent, setAgent] = useActiveState('agent');
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  // const [agreement, setAgreement] = useState(true);
  const [loading, setLoading] = useState();
  const [init, setInit] = useState();

  const [isCheckAuth, setIsCheckAuth] = useState(false);
  const [data, setData] = useState({});
  const [isIndexActivityDatePop, setIsIndexActivityDatePop] = useLocalStorageState('index-activity', []);

  const confirm = useLockFn(() => {
    Dialog.loading();
    general.axios.post('/accountCancel/submit')
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        Dialog.info('注销成功');

        localStorage.removeItem('user_id');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('yx_account');
        localStorage.removeItem('yx_token');
        localStorage.setItem('logout', true);
        PubSub.publish('loginSubscribe',false)
        setIsIndexActivityDatePop(null);
        general.history.push('/screen-user');
        history.push(URI('/verificode').addQuery('callback', "/screen-user").toString());
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  // console.log(general.callback);

  const load = () => {
    if(loading) {
      return;
    }

    setLoading(true);
    Dialog.loading();
    general.axios.post('/accountCancel/getIndexData')
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);
        setData(data.assets)
        // console.log(data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  return <div className="user-assetaccount">
    <div className="group">
      <div className="item">
        <div className="title">
          请确认当前账号资产
        </div>
        <div className="desc">
          <p>
          1.剩余<span>{data?.ptb}</span>平台币
          </p>
          <p>
          2.剩余<span>{data?.balance}</span>金币
          </p>
          <p>
          3.可用优惠券<span>{data?.voucher}</span>张
          </p>
          <p>
          4.VIP剩余<span>{data?.vip}</span>天
          </p>
          <p>
          5.省钱卡剩余<span>{data?.mcard}</span>天
          </p>
        </div>
        <div className="tips">
          建议使用完毕在注销账号，若您点击“仍要注销”则代表放弃当前资产，账号成功注销后无法再使用此资产。
        </div>
      </div>
    </div>

    <div className="user-btn" onClick={()=>setIsCheckAuth(true)}>仍要注销</div>

    {/***认证***/}
    {isCheckAuth ?
    <div className="writeoff-account">
        <div className="auth-index">
          <div className="">
            <div className="title">
              注销提醒
            </div>
            <div className="content">
              <div className="tag1">
                为防止误操作，请再次确认是否要注销账号并确认注销后的影响。再次善意提醒：注销咪噜游戏账号为不可恢复的操作，成功注销账号后您将无法恢复此账号的信息。包括但不限于：
              </div>
              <div className="tag2">
                1.咪噜游戏账号的个人资料和历史信息（包括但不限于头像、昵称、社区内容、浏览记录与收藏等）都将无法找回。<br />
                2.您已开通切在有效期内的增值服务（省钱卡与VIP等）将无法继续使用，你曾经获得的金币、平台比，优惠券、游戏道具、游戏礼包等虚拟资产与权益视为您自行放弃，且无法继续使用。您理解并同意我们无法协助您重新恢复前述服务。
              </div>
              <div className="tag3">
                *申请注销后，180天内未登录账号即可完成账号注销，180天内登录该账号可解除账号注销申请。
              </div>
            </div>
          </div>
          <div className="user-btn-writeoff" onClick={confirm}>确认注销</div>
        </div>
      </div> : null
      }
  </div>;
};