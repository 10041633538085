import useActiveState from "../../../lib/useActiveState";
import Image from "../../../lib/Image";
import Comment from "./Comment";
import Reply from "./Reply";
import {useLocalStorageState, useMount} from "ahooks";
import Question from "./Question";
import Answer from "./Answer";
import KeepScroll from "../../../lib/KeepScroll";
import {useEffect, useState} from "react";
import general from "../../../general";
import Dialog from "../../../lib/Dialog";
import CommunityList from "../../game/detail/Community/comments/CommunityList";

const types = [
  // {
  //   id: 'comment',
  //   text: '我的点评',
  //   view: Comment,
  // },
  // {
  //   id: 'reply',
  //   text: '我的回复',
  //   view: Reply,
  // },
  // {
  //   id: 'question',
  //   text: '我的提问',
  //   view: Question,
  // },
  // {
  //   id: 'answer',
  //   text: '我的回答',
  //   view: Answer,
  // },
  {
    text:'#评论',
    view:CommunityList,
    tagid:'1'
  },
  {
    text:'#攻略',
    view:CommunityList,
    tagid:'2'
  },
  {
    text:'#问答',
    view:CommunityList,
    tagid:'3'
  }
];

export default (props) => {
  const [currentUser] = useActiveState('user');
  const [user, setUser] = useState({member_id: props.query.id || currentUser.member_id});
  const [typeIndex, setTypeIndex] = useState(-1);

  useEffect(()=>{
    if (props.active && typeIndex === -1){
      setTypeIndex(0)
    }
  },[props.active])

  useMount(() => {
    props.setTitle(<div style={{color:'#282828',fontSize:'19px'}}>我的足迹</div>)
    Dialog.loading();
    general.axios.post('/base/comment/getMyCommentInfo', {member_id: user.member_id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setUser({...user, ...data});
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  // const [typeIndex, setTypeIndex] = useLocalStorageState('user-record', -1);

  return <div className="user-record">
    <div className="record-head"  style={{backgroundImage: 'url(/resources/user/user-record.png)'}}>
      <div className="record-user">
        <div className="icon">
          <Image src={user.user_avatar || '/resources/user/avatar.png'} alt=""/>
        </div>
        {/* {user.user_level ? 
        <div className="vip-user-icon">
          <img src={'/resources/user/' + user.user_level + '.png'}/>
        </div>
        : null} */}
        <div className="name">{user.nick_name}</div>
      </div>
      <div className="record-type">
        {types.map((item, index) => <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>{item.text}</div>)}
      </div>
    </div>
    <div className="record-body">

      {types.map((item, index) => <div className={'record-body-item' + (index === typeIndex ? ' active' : '')}>
        <KeepScroll active={props.active && index === typeIndex}>
          <item.view 
            active={props.active && index === typeIndex} 
            user={user}
            tagid={item.tagid}
            isfootMark={true}
            />
        </KeepScroll>
      </div>)}
    </div>
  </div>;
};