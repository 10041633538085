import {useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import {AlipayCircleOutlined, WechatOutlined} from "@ant-design/icons";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import axios from "axios";
import MobileDetect from "mobile-detect";
import general from "../../general";
import URI from "urijs";

export default props => {
  useEffect(() => {
    props.setOperate(<Link to="/ptb/record" className="trade-index-notice" style={{display:"none"}}>充值记录</Link>);
  }, []);
  const {aplus_queue} = window;
  const location = useLocation();
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  const [amount, setAmount] = useState(100);
  const [pay_type, setPay_type] = useState('wxpay');
  const [agent] = useActiveState('agent');
  const mobileDetect = new MobileDetect(navigator.userAgent);
  const [conFimState, setConFimState] = useState(false);
  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  // const [data, setData] = useState({}); //实名认证参数值
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [focus, setFocus] = useState('');

  // useEffect(() => {
  //   if(amount > 50000) {
  //     Dialog.alert("充值金额只能是1-50000");
  //     setAmount(100)
  //   }
  // }, [amount]);

  const submit = useLockFn(async () => {
    if(!general.isLogin()) {
      history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
      return;
    }

    if(user.isCheckAuth && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdult && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }

    if(amount > 50000) {
      Dialog.alert("充值金额只能是1-50000");
      return;
    }
    // if (mobileDetect.is('iPhone')) {
    //   Dialog.loading();
    //   await axios.post('https://sys.wakaifu.com/home/mixPay/pay/?username=' + localStorage.getItem('username') + '&token=' + localStorage.getItem('token') + '&test=1', {amount, pay_type, recharge_type: 'ptb'})
    //     .finally(() => {
    //       Dialog.close();
    //     })
    //     .then(res => {
    //       let {status, data} = res.data;
    //       if (!status.succeed) {
    //         Dialog.error(status.error_desc);
    //       } else {
    //         if (data.wxPayHtml) {
    //           window.location.href = data.wxPayHtml;
    //         }
    //         if (data.zfbPayHtml) {
    //           window.location.href = data.zfbPayHtml;
    //         }

    //         setTimeout(
    //           setConFimState(true)
    //         , 80000)
    //       }
    //     })
    //     .catch(err => {
    //       Dialog.error(err.message);
    //     });
    // } else {
    //   window.location.href="https://app.milu.com/?a=" + agent;
    // }
    Dialog.loading();
    await axios.post('https://sys.wakaifu.com/home/mixPay/pay/?username=' + localStorage.getItem('username') + '&token=' + localStorage.getItem('token') + '&test=1', {amount, pay_type, recharge_type: 'ptb'})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          if (data.wxPayHtml) {
            if(agent == "3b8n" || agent == "1eaz") {
              let hrefUrl = URI('app://browser/')
                      .addQuery('url', URI(data.wxPayHtml)
                          .toString()
                      )
                      .toString();
              
              window.location.href = hrefUrl;
            } else {
              window.location.href = data.wxPayHtml;
            }
            // window.location.href = data.wxPayHtml;
          }
          if (data.zfbPayHtml) {
            if (mobileDetect.is('iPhone')) {
              if(agent == "3b8n" || agent == "1eaz") {
                let hrefUrl = URI('app://browser/')
                      .addQuery('url', URI(data.zfbPayHtml)
                          .toString()
                      )
                      .toString();
              
                window.location.href = hrefUrl;
              } else {
                window.location.href = data.zfbPayHtml;
              }
              // window.location.href = data.zfbPayHtml;
            } else {
              window.location.href = data.topurl;
            }
          }

          setTimeout(()=>{
            setConFimState(true)
          }, 3000)
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  const query = useLockFn(async () => {
    if(!conFimState) {
      return;
    }
    // Dialog.confirm({
    //   title: '是否已支付？',
    //   yes: () => {
    //     Dialog.loading();
    //     general.axios.post('http://api3.app.wakaifu.com/api/public/checkpaystatus', {username:localStorage.getItem('username'), type: 'ptb'})
    //     .finally(() => {
    //       setConFimState(false)
    //       Dialog.close();
    //     })
    //     .then(res => {
    //       console.log(res.status);
    //       if(res.status === 1) {
    //         Dialog.info('支付成功');
    //       } else {
    //         Dialog.info('支付被取消');
    //       }
    //     })
    //     .catch(err => {
    //       Dialog.error('支付被取消');
    //     });
    //   },
    //   no: () => {
    //     setConFimState(false)
    //   }
    // });

    Dialog.dialog({
      type: 'modal',
      mask: true,
      title: '是否已支付？',
      btns: [
        {
          text: '否', onPress: () => {
            setConFimState(false)
          }
        },
        {
          text: '是', onPress: () => {
            Dialog.loading();
            general.axios.post('/api/public/checkpaystatus', {username:localStorage.getItem('username'), type: 'ptb'})
            .finally(() => {
              setConFimState(false)
              Dialog.close();
            })
            .then(res => {
              // console.log(res);
              if(res.data.status == 1) {
                Dialog.info('支付成功');

                //ptb支付成功埋点
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['RechargePlatformCurrencySuccess', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), amount:amount, pay_type: pay_type, agent: agent}]
                });
                // window.location.href = window.location.href;
                // setUser({...user, platform_coins: parseFloat(user.platform_coins) + parseFloat(amount * 10)});
              } else {
                Dialog.info('支付被取消');
              }
            })
            .catch(err => {
              Dialog.error('支付被取消');
            });
          }
        }
      ]
    });
  });

  useEffect(() =>{
    query();
  }, [conFimState]);

  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;

        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  return <div>
    <div className="ptb-index">
      {!general.isLogin() ? null :
      <div className="ptb-amount">
        <div className="name">平台币余额</div>
        <div className="value">{user.platform_coins || '?'}</div>
      </div>
      }
      <div className="amount-title">请选择充值金额<span>（1平台币=0.1元人民币）</span></div>
      <div className="amount-item">
        {[50, 100, 500, 1000, 2000, 5000].map(item => <div className={'item' + (amount === item ? ' active' : '')} onClick={() => setAmount(item)}>{item}</div>)}
      </div>
      <div className="amount-input">自定义金额：<input type="tel" value={amount} placeholder="1-50000" onChange={e => setAmount(e.target.value)}/></div>
      <div className="amount-result">可获得<span>{amount * 10}</span>平台币</div>
      <div className="pay-title">请选择支付方式</div>
      <div className="pay-type">
        <div className={'item' + ('alipay' === pay_type ? ' active' : '')} onClick={() => setPay_type('alipay')}>
          <AlipayCircleOutlined className="alipay"/>支付宝
        </div>
        <div className={'item' + ('wxpay' === pay_type ? ' active' : '')} onClick={() => setPay_type('wxpay')}>
          <WechatOutlined className="wxpay"/>微信
        </div>
      </div>
      {
        user?.is_oversea == "2" ?
        <div className="pay-type">
          <div className={'item' + ('mycard' === pay_type ? ' active' : '')} onClick={() => setPay_type('mycard')}>
            <img src="https://storage.milu.com/sys/Public/home/mixpay/images/mycard.png" className="mycard"/>mycard
          </div>
          <div className={'item' + ('paypal' === pay_type ? ' active' : '')} onClick={() => setPay_type('paypal')}>
            <img src="https://storage.milu.com/sys/Public/home/mixpay/images/paypal.png" className="paypal"/>paypal
          </div>
        </div>
        : null
      }
      <div className="pay-btn" onClick={submit}>
        立即充值
        {(()=>{
          //根据支付类型来显示支付金额
          if(pay_type == 'mycard') {
            return "NT$" + (amount * user?.twd * (1 + user?.twd_float)).toFixed(2);
          } else if(pay_type == 'paypal') {
            return "$" + (amount * user?.usd * (1 + user?.usd_float)).toFixed(2);
          } else {
            return "￥" + amount;
          }
        })()}
      </div>
    </div>
    {/***实名认证弹框***/}
    {
    isCheckAuth?
    <div className="pay-check-auth">
      <div className="auth-index">
        <div className="close" onClick={() => setIsCheckAuth(false)}>
          <img src="/resources/user/close.png" />
        </div>
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>实名认证</h3>
            <p>
              {config.authTopTips}
              {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
            </p>
          </div>
        </div>

        <div className="auth-user-realname">
          <div className="auth-user-input">
            <div className="name">真实姓名</div>
            <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
          </div>
          <div className="auth-user-input">
            <div className="name">身份证号</div>
            <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
          </div>
          <span>
            {config.authBottomTips}
          {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
          </span>
          <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
        </div>
      </div>
    </div>
    : null
    }
    {/** 提示弹框 **/}
    {
    isCheckAdult ? 
    <div className="pay-check-auth">
      <div className="auth-index adult-index">
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>温馨提示</h3>
            <p>
              {config.adultTips}
              {/* 根据国家新闻出版署最新规定，禁止向未成年用户提供支付服务。 */}
            </p>
          </div>
        </div>
        <div className="auth-user-realname">
          <div className="auth-user-btn" onClick={()=>setIsCheckAdult(false)}>我知道了</div>
        </div>
      </div>
    </div>
    : null
    }

  </div>;
};