import {useHistory} from "react-router-dom";
import React, {useState} from "react";
import general from "../../general";
import {CloseOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import {useLocalStorageState} from "ahooks";
import useActiveState from "../../lib/useActiveState";

export default props => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [focus, setFocus] = useState('');
  const [password, setPassword] = useState({});
  const [saving, setSaving] = useState(false);
  const [tk, setTk] = useActiveState('tk');
  const [tkUrl, setTkUrl] = useActiveState('tkUrl');
  const [tkStatus, setTkStatus] = useLocalStorageState('register-tk-status', 0);

  const save = () => {
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }
    setSaving(true);
    Dialog.loading();
    general.axios.post('/setPassword', {
      headers: {username: localStorage.getItem('username'), token: localStorage.getItem('token')},
      member_id: localStorage.getItem('user_id'),
      password: data.password,
    })
        .finally(() => {
          setSaving(false);
          Dialog.close();
        })
        .then(response => {
            let {status, data} = response.data;
            if (!status.succeed) {
                Dialog.error(status.error_desc);
                return;
            }
            localStorage.setItem('token', data.token);
            localStorage.setItem('user_id', data.user_id);
            localStorage.setItem('username', data.username);
            aplus_queue.push({
                action: 'aplus.record',
                arguments: ['login', 'login', {
                username: data.username
                }]
            });
            // setTk('');
            // setTkUrl('');
            // setTkStatus(1);
            Dialog.success('设置成功', () => general.goBack(true));
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  };

  return <div className="user-realname">
    <div className="user-input">
      <div className="name">密码</div>
      <input name="password" type={!password.password ? 'password' : 'text'} value={data.password} placeholder="请输入密码" autoComplete="off" onFocus={() => setFocus('password')} onChange={e => setData({...data, password: e.target.value})}/>
      <div className="password" onClick={() => setPassword({...password, password: !password.password})}>
        {password.password ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
      </div>
      {focus === 'password' && data.password ? <CloseOutlined className="close" onClick={() => setData({...data, password: ''})}/> : null}
    </div>
    <div className="user-btn" onClick={save}>保存</div>
  </div>;
};