import React, { useEffect, useRef, useState } from 'react'
import './css/CommunityDetail.css'
import Dialog from '../../../../../lib/Dialog';
import general from '../../../../../general';
import { Avatar } from 'antd-mobile';
import CommunityItem from '../comments/CommunityItem';
import moment from 'moment';
import { BigPlayButton, ControlBar, Player, Shortcut } from 'video-react'
import { useScroll } from 'ahooks';
import { Link } from 'react-router-dom'
import Image from '../../../../../lib/Image';
import ComnunitySort from '../comments/ComnunitySort';
import { NavLink } from '../../../../../lib/Navigation';

export const monitorSoftKeyboard = callback => {
  const originalHeight = document.documentElement.clientHeight || document.body.clientHeight
  window.addEventListener('resize',()=>{
    const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight
    if (Math.abs(resizeHeight - originalHeight) < 10) {
      callback(false)
    }else{
      callback(true)
    }
  })
  window.addEventListener('focusin', () => {  //软键盘弹起事件
    callback(true);
  })
  window.addEventListener('focusout', () => { //软键盘关闭事件
    callback(false);
  })
}

export default function CommunityDetail(props) {

  const [loading,setLoading] = useState(false)
  const [info,setInfo] = useState()
  const [comments,setComments] = useState([])
  const [total,setTotal] = useState(0)
  const [like,setLike] = useState(false)
  const [like_count,setLike_count] = useState()
  const [rid,setRid] = useState('')
  const [name,setName] = useState()
  const [sortAlert,setSortAlert] = useState(false)
  const [sort,setSort] = useState({title:'最新回复',sort:'new_reply'})
  const [top,setTop] = useState(0)
  const inputRef = useRef()
  const id = props.query.id
  const tagid = props.query.tagid

  useEffect(()=>{
    props.setTitle(<div>{parseInt(tagid) === 1 ? '评论详情' : parseInt(tagid) === 2 ? '攻略详情' : '问答详情'}</div>)
    getDetail()
  },[])

  const scroll = useScroll()
  useEffect(()=>{
    if (props.active){
      setTimeout(() => {
        const newsort = document.getElementById('newsort')
        if (newsort){
          setTop(newsort.getBoundingClientRect().top)
        }
      }, 500);
      if (scroll.top < -80) {
        getComment()
      }
    }
  },[scroll,props.active])

  const getDetail = ()=>{
    if (loading){
      return
    }
    setLoading(true)
    Dialog.loading()
    general.axios.post('/base/community/getCommunityInfo',{id,tagid})
    .finally(()=>{
      setLoading(false)
      Dialog.close()
      getComment()
    })
    .then(res=>{
      const {status,data} = res.data
      if (status.succeed){
        setInfo(data)
        setLike(data.me_like === '1')
        setLike_count(data.like_count)
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  const getComment = ()=>{
    if (loading){
      return
    }
    setLoading(true)
    Dialog.loading()
    general.axios.post('/base/community/getCommunityListForComment',{tagid,id,sort:sort.sort})
    .finally(()=>{
      setLoading(false)
      Dialog.close()
    })
    .then(res=>{
      const {status,data} = res.data
      if (status.succeed){
        setComments(data.comment_list)
        setTotal(data.paginated.total)
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  const likeAction = ()=>{
    if (like){
      return
    }
    general.axios.post('/base/community/likeCommunity',{tagid:info.tagid,id:info.id})
    .then(res=>{
      const {status,data} = res.data
      if (status.succeed){
        setLike(true)
        let count = parseInt(like_count)+1
        setLike_count(count)
        Dialog.toast({
          icon: 'agree',
          content: '',
          duration: 1,
          mask: false,
          onClose:()=>{},
        });
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  const keyUp = (e)=>{
    if (e.keyCode === 13) {
      if (loading){
        return
      }
      if (inputRef.current.value.length === 0){
        return
      }
      comment()
    }
  }

  const commentAction = (id,name)=>{
    setRid(id)
    setName(name)
    setTimeout(() => {
    }, 100);
    inputRef.current.focus()
  }

  const comment = ()=>{
    if (!general.isLogin()){
      general.history.push('/verificode')
      return
    }
    setLoading(true)
      Dialog.loading()
      const params = {
        tagid:info.tagid,
        id:info.id,
        content:inputRef.current.value,
        reply_id:rid
      }
      inputRef.current.blur()
      general.axios.post('/base/community/communityForComment',params)
      .finally(()=>{
        setLoading(false)
        Dialog.close()
      })
      .then(res=>{
        const {status,data} = res.data
        if (status.succeed){
          inputRef.current.value = ''
          getComment()
        }else{
          Dialog.error(status.error_desc)
        }
      })
  }

  useEffect(()=>{
    monitorSoftKeyboard(isUp=>{
      if (isUp){

      }else{
        setRid('')
        setName()
      }
    })
  },[])

  const sortAction = (e)=>{
    setSortAlert(false)
    if (e.sort){
      setSort(e)
    }
  }

  useEffect(()=>{
    getComment()
  },[sort])

  return (
    <div className='community-detail'>
      <div className="container">
        <div className="detail-header">
          <div className="type">
            <img src={"resources/community/icon_" + (parseInt(tagid) === 1 ? "comment" : (parseInt(tagid) === 2 ? "strategy" : "question")) + ".png"} alt="" />
          </div>
          {info?.title ? <div className="title">{info.title}</div> : null}
          <div className="userinfo">
            <Avatar className='avatar' src={info?.user_icon}/>
            <div className="content">
              <div className="username">{info?.user_nickname}</div>
              <div className="time" style={{display:info ? '' : 'none'}}>{(() => {
                var date = new Date(info?.time * 1000)
                let Y = date.getFullYear()
                let M = date.getMonth()+1
                let D = date.getDate() < 10 ? '0'+date.getDate() : date.getDate()
                let H = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':'
                let m = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()
                var time = Y + '-' + M + '-' + D
                if (time === moment().format('YYYY-MM-DD')) {
                  return '今日 ' + H + m;
                } else if (time == moment().subtract(1, 'd').format('YYYY-MM-DD')) {
                  return '昨日 ' + H + m;
                } else {
                  return M + '月' + D + '日' + H + m
                }
              })()} • 共发表{info?.user_community_count}条游戏评论</div>
            </div>
          </div>
          {info?.video?.length > 0 ? <div className="video">
            <Player
              autoPlay={true}
              playsInline={true}
              src={info.video}
            >
              <Shortcut></Shortcut>
              <BigPlayButton position='center'></BigPlayButton>
              <ControlBar autoHide={true}></ControlBar>
            </Player>
          </div> : null}
          {info?.pic_list?.length > 1 ?  
          <div className="imgs">
            {info?.pic_list?.map((img, index, imgs) => <Link to={'/img?imgs=' + img + '&img=' + index} className="img"><Image src={img} alt=""/></Link>)}
          </div> : null}
          {info?.pic_list?.length === 1 ?
          <div className="wap">
            <Link to={'/img?imgs=' + info.pic_list[0] + '&img=0'} className="img"><Image className='image' src={info.pic_list[0]} alt=""/></Link>
          </div> : null}
          <div className="contents" dangerouslySetInnerHTML={{__html:info?.content}}></div>
        </div>
        <div className="comments">
          <div className="toolbar">
            <div className="number">全部回复 {total}</div>
            <div id='newsort' className="sort" onClick={()=>setSortAlert(!sortAlert)}>
              <span>{sort.title}</span>
              <img src="resources/community/sort.png" alt="" />
            </div>
          </div>
          <div className="list">
            {comments.map(item=>{
              return <CommunityItem key={item.id} item={item} reply={true} commentAction={commentAction}></CommunityItem>
            })}
          </div>
          {comments?.length === 0 && info ? <div className="empty"><img src="/resources/game/detail-commont-place.png" alt=""/>暂无回复，抢个沙发哦~</div> : null}
        </div>
        <div className="comment-reply">
          <div className="input">
            <input ref={inputRef} enterkeyhint="send" placeholder={name ? '回复' + name : '到我发言了'} onKeyUp={keyUp}/>
            <NavLink user={true}>
              {parseInt(info?.tagid) === 3 ? 
              <div className='send' onClick={()=>{
                if (general.isLogin()){
                  if (loading){
                  return
                }
                if (inputRef.current.value.length === 0){
                  return
                }
                comment()
                }
              }}>发送</div> :
              <div className="like" onClick={e=>{
                if (general.isLogin()){
                  likeAction()
                }
              }}>
                <img src={"resources/community/" + (like ? "liked.png" : "like.png")} alt="" />
                <div>{like_count}</div>
              </div>}
            </NavLink>
          </div>
        </div>
      </div>
      {sortAlert ? 
      <ComnunitySort 
        top={top} 
        sortAction={sortAction}
        sortList={[
          {
            title:'最新回复',
            sort:'new_reply'
          },
          {
            title:'最早回复',
            sort:'early'
          },
          {
            title:'最热回复',
            sort:'hottest'
          }
          ]}
      ></ComnunitySort> : null}
    </div>
  )
}
