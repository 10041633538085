import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import general from "../../general";
import {CloseOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {useLocalStorageState, useToggle, useLockFn} from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";
import { CenterPopup } from 'antd-mobile'

const mobileDetect = new MobileDetect(navigator.userAgent);
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}

let rand = randomNum(100000000, 999999999);

export default props => {
  const history = useHistory();
  const {aplus_queue} = window;
  const [app] = useActiveState('app');
  useEffect(() => {
    // props.setOperate(<Link to="login" className="account-register-btn">登录</Link>);
  }, []);
  const [focus, setFocus] = useState('');
  const [password, {toggle: togglePassword}] = useToggle(false);
  const [repeatPassword, {toggle: toggleRepeatPassword}] = useToggle(true);
  const [data, setData] = useState(!prod ? {
    username: rand,
    password: rand,
    // repeatPassword: rand,
  } : {});
  // const [agreement, setAgreement] = useLocalStorageState('agreement', false);
  const [config, setConfig] = useActiveState('config');
  const [agreement, setAgreement] = useState(true);
  const [registering, setRegistering] = useState(false);
  const [datas, setDatas] = useLocalStorageState('users', []);
  const [user, setUser] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [tk, setTk] = useActiveState('tk');
  const [tkUrl, setTkUrl] = useActiveState('tkUrl');
  const [tkStatus, setTkStatus] = useLocalStorageState('register-tk-status', 0);
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作
  const [visible, setVisible] = useState(false)
  const [autoLogin, setAutoLogin] = useState(false)

  useEffect(() => {
    if (autoLogin && agreement){
      register()
    }
  }, [autoLogin,agreement]);

  const register = () => {
    if (!data.username) {
      Dialog.error('请输入用户名');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }
    // if (!data.repeatPassword) {
    //   Dialog.error('请确认密码');
    //   return;
    // }
    // if (data.password !== data.repeatPassword) {
    //   Dialog.error('两次输入的密码不一致');
    //   return;
    // }
    if (!agreement) {
      // Dialog.error('请阅读下方的协议');
      // Dialog.info('请先阅读并同意《用户协议》和《隐私政策》');
      setVisible(true)
      return;
    }
    if (registering) {
      return;
    }

    // if(agent == 'rr2f') {
    //   Dialog.info("当前无法完成注册，请联系客服进行处理！");
    //   return;
    // }
    
    data.regtype = '5';
    // data.tk = tk;
    // data.tk_url = tkUrl;

    let location = window.location.href;
    if(window.location.host == 'webbox.99maiyou.cn') {
      if(params.agent) {
        location = window.location.href
      } else {
        location = window.location.href + "?agent=" + agent;
      }
    }

    data.location_href = agent == 'rr2f' ? location : '';
    
    setRegistering(true);
    Dialog.loading();
    general.axios.post('/user/user/register', data)
        .finally(() => {
          setRegistering(false);
          Dialog.close();
        })
        .then(response => {
          let {status, data: result} = response.data;
          console.log()
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            localStorage.setItem('token', result.token);
            localStorage.setItem('user_id', result.user_id);
            localStorage.setItem('username', data.username);
            setDatas([...datas, {username: data.username, password: data.password}]);
            // Dialog.success('注册成功', () => history.push('/phone'));
            // setTk('');
            // setTkUrl('');
            // setTkStatus(1);
            setWhether('true');
            Dialog.success('注册成功', () => general.goBack(true));
            load_load()
            // setUser({isLogin: true, member_id: result.user_id});

            //临时测试记录--渠道号不一致
            if(agent == 'rr2f') {
              // temporaryAdjuct();
            }

          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['AccountPasswordRegisterSuccess', 'CLK', {age: data.member_info.age, sex: data.member_info.sex, level: data.member_info.vip_level, username: data.member_info.username, agent: agent}]
          });

          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['register', 'CLK', {age: data.member_info.age, sex: data.member_info.sex, level: data.member_info.vip_level, username: data.member_info.username, agent: agent}]
          });
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })

  const temporaryAdjuct = ()=>{
    let data = {};
    data.agent = agent;
    data.host = window.location.href;
    data.username = localStorage.getItem("username");
    data.type = 'register';
    general.axios.post('/home/testGame/temporaryAdjuct', data)
        .finally(() => {
          setRegistering(false);
          Dialog.close();
        })
        .then(response => {
       
        })
        .catch(err => {

        });
  }

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  return <div className="account-register">
    <div className="body">
      {/* <div className="logo"><img src={app.applogo} alt=""/></div> */}
      <div className="input-item">
        <div className="label">账号</div>
        <div className="input">
          <input name="username" type="text" value={data.username} placeholder="请输入账号" autoComplete={false}
                 onFocus={e => setFocus('username')} onChange={e => setData({...data, username: e.target.value})}/>
          {focus === 'username' && data.username ?
              <CloseOutlined className="assist close" onClick={() => setData({...data, username: ''})}/> : null}
        </div>
      </div>
      <div className="input-item">
        <div className="label">密码</div>
        <div className="input">
          <input name="password" type={password ? 'password' : 'text'} value={data.password} placeholder="请输入6-8位密码"
                 onFocus={e => setFocus('password')} onChange={e => setData({...data, password: e.target.value})}/>
          {/* <div className="assist password" onClick={() => togglePassword()}>
            {password ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
          </div> */}
          {focus === 'password' && data.password ?
              <CloseOutlined className="assist close" onClick={() => setData(data => ({...data, password: ''}))}/> : null}
        </div>
      </div>
      
      {/*<div className="input-item">
        <div className="label">确认密码</div>
        <div className="input">
          <input name="password" type={repeatPassword ? 'password' : 'text'} value={data.repeatPassword}
                 placeholder="请再次确认密码" onFocus={e => setFocus('repeatPassword')}
                 onChange={e => setData({...data, repeatPassword: e.target.value})}/>
          <div className="assist password" onClick={() => toggleRepeatPassword()}>
            {repeatPassword ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
          </div>
          {focus === 'repeatPassword' && data.repeatPassword ? <CloseOutlined className="assist close"
                                                                              onClick={() => setData(data => ({
                                                                                ...data,
                                                                                repeatPassword: ''
                                                                              }))}/> : null}
        </div>
      </div>*/}

      <div className={'btn ' + (registering ? 'disable' : '')} onClick={register}>立即注册</div>

      <div className="account-agreement">
        <label>
          <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
          登录即代表您同意
          <NavLink to={(() => {
              let url = URI('//api3.app.wakaifu.com/userAgreement')
                  .addQuery('title', '用户协议')
                  .addQuery('channel', agent)
                  .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                  .toString();
                return url;
              })()}
              iframe={{title: '《用户协议》'}}
              user={false}
            >《用户协议》</NavLink>
          <NavLink to={(() => {
              let url = URI('//api3.app.wakaifu.com/privacyPolicy')
                  .addQuery('title', '隐私政策')
                  .addQuery('channel', agent)
                  .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                  .toString();
                return url;
              })()}
              iframe={{title: '《隐私政策》'}}
              user={false}
            >《隐私政策》</NavLink>
          <br/>
          接受免除或者限制责任、诉讼管辖约定等粗体标示条款
        </label>
      </div>
    </div>
    <CenterPopup visible={visible} onMaskClick={()=>setVisible(false)}>
      <div className="centerpopup-login-alert">
        <div className="centerpopup-login-alert-container">
          <div className="centerpopup-content-title">注册须知</div>
          <div className="centerpopup-content-content">
            我已阅读同意<NavLink className="centerpopup-content-link" to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api3.app.wakaifu.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
            、<NavLink className="centerpopup-content-link" to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api3.app.wakaifu.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>接受免除或者限制责任、诉讼管辖约定等粗体标识条款
          </div>
          <div className="centerpopup-content-agree" onClick={()=>{
            setAgreement(true)
            setVisible(false)
            setAutoLogin(true)
          }}>同意并注册</div>
          <div className="centerpopup-content-cencel" onClick={()=>setVisible(false)}>取消</div>
        </div>
      </div>
    </CenterPopup>
    {/* <div className="account-agreement">
      <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        登录即代表您同意
        <NavLink to={(() => {
            let url = URI('//api3.app.wakaifu.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
        <NavLink to={(() => {
            let url = URI('//api3.app.wakaifu.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>
        <br/>
        接受免除或者限制责任、诉讼管辖约定等粗体标示条款
      </label>
    </div> */}
  </div>;
};