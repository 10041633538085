import React, { useEffect, useRef, useState } from 'react'
import { ImageViewer, Image } from 'antd-mobile'
import moment from "moment";
import general from '../../general';

export default function GfyxItem(props) {

  const [trade] = useState(props.children);
  const [visible, setVisible] = useState(false)
  const [imgs, setImgs] = useState([])
  const ref = useRef()

  useEffect(()=>{    
    if (trade.game_own_screenshots.length > 2){
      setImgs(trade.game_own_screenshots.slice(0,3))
    }else {
      setImgs(trade.game_own_screenshots)
    }
  },[trade])

  const handleTapImg = (index)=>{
    setVisible(true)
    ref.current.swipeTo(index)
    const element = document.getElementsByClassName('trade-index')[0]
    element.style.overflow = 'visible'
  }

  return (
    <div className='gfyx-item' onClick={()=>{
      general.history.push('/trade/detail?id=' + trade.trade_id)
    }}>
      <div className="content">
        <div className="info">
          <div className='game-name'>
            <span className='name'>{trade.game_name}</span>
            <span className='nameRemark'>{trade.nameRemark}</span>
          </div>
          <div className="text">{trade.title}</div>
          <div className="text">区服：{trade.server_name}&nbsp;累充金额：{trade.total_amount}元</div>
        </div>
        <div className="price">{trade.trade_price}</div>
      </div>
      <div className="imgs">
        {imgs.map((item,index)=>{
          return <div key={index} className='img' onClick={e=>{
              e.preventDefault();
              e.stopPropagation();
              handleTapImg(index)}
            }>
            <Image placeholder={
              <img src='resources/placeholder/game-icon.png' alt="" />
            } key={index} src={item.thumb} alt="" />
          </div>
        })}
      </div>
      <div className="time">
        上架时间:{moment(trade.sell_time * 1000).format('YYYY-MM-DD HH:mm:ss')}
      </div>
      <ImageViewer.Multi
        classNames='mask'
        images={trade.game_own_screenshots.map(e=>e.source)}
        visible={visible}
        defaultIndex={0}
        ref={ref}
        onClose={e => {
          setVisible(false)
          const element = document.getElementsByClassName('trade-index')[0]
          element.style.overflow = 'scroll'
        }}
      />
    </div>
  )
}
