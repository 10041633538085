import {useEffect, useState, useCallback, React} from "react";
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import Cover from "./Cover";
import Result from "./Result";
import useActiveState from "../../lib/useActiveState";
import { useDebounce, useDebounceFn, useInterval } from "ahooks";
import debounce from 'lodash/debounce';
import $ from 'jquery';
import { createRef } from "react";
import Dialog from "../../lib/Dialog";

export default ({active, setTitle, setOperate}) => {
  const [config] = useActiveState('config');
  const [kw, setKw] = useState('');
  const [refresh, setRefresh] = useState('');
  const sendQuery = (query) => setKw(query);
  const [inputFlag, setInputFlag] = useState(false);
  const [inputText, setInputText] = useState('');
  let isOnComposition = false;
  // let isInnerChangeFromOnChange = false;

  const searchOpate = (e) => {

  }

  const handleClick = useDebounce((e) => {
    
    
  }, 1000)

  const delayedQuery = useCallback(debounce(q => sendQuery(q), 1500), []);
  const onChange = e => {
    // setKw(e.target.value);
    setInputText(e.target.value);
    delayedQuery(e.target.value);
  };

  const handleComposition = (e) => {
    // console.log(e.type + ": " + e.target.value);
    // console.log(inputFlag);
    
    if (e.type === 'compositionend') {
      // composition is end
      const value = e.target.value;
      // console.log("end")
      // console.log(value);
      setInputFlag(false);
      isOnComposition = false;
      setInputText(e.target.value);
      setKw(e.target.value);
      return;
      // handleFixedChange(value);
      // setKw(e.target.value);
      // handleFixedChange(e);
    } else if (e.type === 'compositionstart') {
      // Dialog.info(e.type);
      isOnComposition = true;
      setInputFlag(true);
      // console.log("start")
      return;
    } else if (e.type === 'compositionupdate') {
      // Dialog.info(e.type);
      isOnComposition = true;
      setInputFlag(true);
      return;
    }
    
    isOnComposition = true;
    // console.log(inputFlag);
    // setInputText(e.target.value);
  }

  // const handleFixedChange = (e)=>{
  //   setInputText(e.target.value);
  //   console.log(isOnComposition);
  //   setTimeout(() => {
  //     if(!isOnComposition) {
  //       // console.log("onChange" + ": " + e.target.value);
  //       setKw(e.target.value);
  //     }
  //   }, 1000);
  // }

  const handleChange = (e) => {
   // Flow check
  //  if (!(e.target instanceof HTMLInputElement)) return
 
  //  if (isInnerChangeFromOnChange) {
  //   setInputText(e.target.value);
  //   setKw(e.target.value)
  //   //  this.setState({ inputValue: e.target.value, innerValue: e.target.value })
  //   isInnerChangeFromOnChange = false
  //   return
  //  }

    // if (!isOnComposition) {
    //   setInputText(e.target.value);
    //   setKw(e.target.value)
    // }
    setInputText(e.target.value)
    if (!isOnComposition) {
      // setInputText(e.target.value);
      setKw(inputText)
    }
  }

  const handleFixedChange = (e)=>{
    // console.log("失去焦点");
  }

  // const inputRef = createRef();

  // useEffect(()=>{
  //   inputRef.current = kw;
  //   console.log(inputRef);
  // }, [inputRef])

  const inputRef = useCallback(node => {
    // console.log()
    if (node) {
      node.addEventListener('input', e => {
        let currentInput = e.data;
        // console.log(e);
        // if (e.inputType == 'insertCompositionText') {
        //   // 如果输入的是汉字或者希腊字母，则返回true
        //   if (currentInput && currentInput.match(/[\u4e00-\u9fa5]+|[\u0370-\u03ff]+/)) {
        //     console.log("123");
        //   }
        //   return false;
        // }
        // console.log(e.inputType);
        // console.log(e.target.value);
        if(e.inputType == 'insertText') {
          setKw(e.target.value);
        }

        if(e.inputType == 'deleteContentBackward') {
          setKw(e.target.value);
        }

        if(e.inputType == 'insertFromPaste') {
          setKw(e.target.value);
        }
      }, {passive: false});
    }
  }, []);

  // console.log(config.app_home_search_default_title_list.length);

  // const [placeNum, setPlaceNum] = useState();

  // const testpo = (n)=>{
  //   let length = config.app_home_search_default_title_list.length;

  //   if(n >= length) {
  //     n = 0;
  //   }
  //   if(config.app_home_search_default_title_list) {
  //     setPlaceNum(config.app_home_search_default_title_list[n]);
  //   }

  //   console.log(placeNum);
  // }


  // useEffect(()=>{
  //   let n = 0;
  //   setInterval(()=>{
  //     let length = config.app_home_search_default_title_list.length;

  //     if(n > length) {
  //       n = 0;
  //     }
  //     if(config.app_home_search_default_title_list) {
  //       setPlaceNum(config.app_home_search_default_title_list[n]);
  //       n++;
  //     }
  //   }, 3000)

  //   console.log(placeNum);
  // });

  // console.log(inputText);

  // console.log(config.app_home_search_default_title_list);

  const [searchRollList, setSearchRollList] = useState([]); //滚动搜索

  useEffect(()=>{
    if(config) {
      setSearchRollList(config.app_home_search_default_title_list)
    }
  }, [config, config.app_home_search_default_title_list]);

  const begin = () => {
    if(searchRollList && searchRollList.length > 0) {
      let info = searchRollList;
      info.push(searchRollList[0]);
      info.splice(0,1);
      setSearchRollList('');
      setSearchRollList(info);

      // console.log(searchRollList);
    }
  }
  
  useInterval(()=>{
    begin();
  }, 3000);

  useEffect(() => {
    setTitle(<div className="search-index-input">
      {/* <SearchOutlined className="icon"/> */}
      <img src="/resources/user/ss.png" className="icon" />
      {/* <input type="text" value={kw} placeholder={config.app_home_search_default_title || '输入关键词搜索'}
             onChange={e => setKw(e.target.value)} /> */}

      <input type="text" value={inputText} placeholder={searchRollList !== undefined ? searchRollList.length > 0 ? searchRollList[0]: '输入关键词搜索' : '输入关键词搜索'}
            onCompositionStart = {handleComposition}
            onCompositionUpdate = {handleComposition}
            onCompositionEnd = {handleComposition}
            onChange={e => setInputText(e.target.value)} ref={inputRef} />

      {kw || inputText ? <div className="close" onClick={(e) => {setKw('');setInputText('')}}><img src="/resources/search/search_close.png" /></div> : null}
    </div>);
    setOperate(<div className="search-index-btn" onClick={() => {
      setKw('');
      setKw(inputText ? inputText : (searchRollList !== undefined ? searchRollList.length > 0 ? searchRollList[0]: '' : ''));
      setInputText(inputText ? inputText : (searchRollList !== undefined ? searchRollList.length > 0 ? searchRollList[0]: '' : ''));
      setRefresh({});
    }}>搜索</div>);
  }, [inputText, searchRollList]);

  return <>
    <div className="search-index">
      <Cover active={active && !kw} kw={kw} setKw={setKw} setInputText={setInputText}/>
      <Result active={active && !!kw} kw={kw} refresh={refresh}/>
    </div>
  </>;
};