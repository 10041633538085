import {useHistory, Link} from "react-router-dom";
import React, {useState, useEffect} from "react";
import general from "../../general";
import {CloseOutlined} from "@ant-design/icons";
import {useInterval} from "ahooks";
import {prod} from "../../env";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [data, setData] = useState(!prod ? {mobile: '12345678901', code: '123456'} : {});
  const [focus, setFocus] = useState('');

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);

  const [areaNumData, setAreaNumData] = useState([]);

  useEffect(() => {
    setAreaNumData(general.areaNumber);
  }, [props.active]);

  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: (areaNumData?areaNumData:'86') + '-' + data.mobile,
      code: 'code',
      type: '02',
    })
        .finally(() => {
          setCoding(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            setCodeTime(60);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const [saving, setSaving] = useState(false);
  const save = () => {
    if (saving) {
      return;
    }
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    setSaving(true);
    Dialog.loading();
    general.axios.post('/user/user/bindPhone', data)
        .finally(() => {
          setSaving(false);
          Dialog.close();
        })
        .then(res => {
          let {status} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            Dialog.success('保存成功', () => {
              setUser({...user, mobile: data.mobile.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')});
              history.goBack();
            });
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  return <div className="user-nickname">
    <div className="user-input">
      <div className="name">
        {/* 手机号 */}
        <div className="area-num-text">
          <img src="/resources/account/jia.png" />
          <Link to="/account/country" className="value">
            {areaNumData ? areaNumData : '86'}
          </Link>
          <img src="/resources/account/sel-down.png" />
        </div>
      </div>
      <input name="mobile" type="text" value={data.mobile} placeholder="请输入手机号" autoComplete="off" onFocus={() => setFocus('mobile')} onChange={e => setData({...data, mobile: e.target.value})}/>
      {focus === 'mobile' && data.mobile ? <CloseOutlined className="close" onClick={() => setData({...data, mobile: ''})}/> : null}
    </div>
    <div className="user-input">
      <div className="name">验证码</div>
      <input name="code" type="text" value={data.code} placeholder="请输入验证码" autoComplete="off" onFocus={() => setFocus('code')} onChange={e => setData({...data, code: e.target.value})}/>
      <div className={'btn ' + (coding || codeTime ? 'disable' : '')} onClick={code}>
        {coding ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
      </div>
    </div>
    <div className="user-btn" onClick={save}>保存</div>
  </div>;
};