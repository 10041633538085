import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";

export default props => {
  const history = useHistory();
  const [user] = useActiveState('user');
  const [data] = useState(general.rebate || {});
  const [rebateContent, setRebateContent] = useActiveState('rebateContent');
  const [contentText, setContentText] = useState([]);

  useEffect(() => {
    if(rebateContent) {
      let tipwx = rebateContent.split('\r\n');
      setContentText(tipwx);
    }
  },[rebateContent])

  return <div className="rebate-success">
    <div className="rebate-success-head" style={{backgroundImage: 'url(/resources/rebate/success-bg.png)'}}>
      <div className="icon-coupler"><img src="/resources/rebate/success-bg2.png" /></div>
      <div className="title">提交成功</div>
    </div>
    <div className="rebate-success-index" >
      {/* <div className="icon"><img src="/resources/rebate/success.png" alt=""/></div>
      <div className="title">提交成功</div> */}
      <div className="info">
        <dl>
          <dt>游戏名称</dt>
          <dd>{data.game_name}</dd>
        </dl>
        <dl>
          <dt>申请账号</dt>
          <dd>{user.member_name}</dd>
        </dl>
        <dl>
          <dt>角色名</dt>
          <dd>{data.role_name}</dd>
        </dl>
        <dl>
          <dt>充值金额：</dt>
          <dd><span>{data.can_rebate_amount}元</span></dd>
        </dl>
      </div>
      <div className="tips">
        <div className="title">温馨提示</div>
        <div className="content">
          {
            contentText?.map(item => <p>
              {item}
            </p>)
          }
          {/* <p>1.返利发放：晚九点前提交的返利均在一个工作日发放；</p>
          <p>2.返利形式：游戏内发放（邮件领取／直接到角色上）激活码形式 发放（注意查看App信息）；</p>
          <p>3.若申请后超过2个工作日未收到，可点击详情内申请核实。</p> */}
        </div>
      </div>
      <div className="operate">
        <div className="btn" onClick={() => {
          if(data?.wechatWorkUrl) {
            window.location.href = data.wechatWorkUrl;
          } else {
            history.replace('/service')
          }
          }}>联系客服 优先发放</div>
        {/* <div className="btn" onClick={() => history.block}>返回首页</div>
        <div className="btn" onClick={() => history.replace('/rebate/detail?id=' + props.query.id)}>订单详情</div> */}
      </div>
    </div>
  </div>;
};