import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import moment from "moment";
import $ from 'jquery';
import {useScroll} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from '../../../lib/useActiveState';

export default props => {
  const {aplus_queue} = window;
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [gameGroups, setGameGroups] = useState([]);
  const [more, setMore] = useState(true);
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [randomColorInfo] = useActiveState('randomColorInfo');
  
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('/newGames', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          setInit(true);
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }
          let tmp = parms.page === 1 || !parms.page ? [] : gameGroups;
          result.list.forEach(item => {
            let index = tmp.findIndex(value => value.text == item.text);
            if (index == -1) {
              index = tmp.length;
              tmp.push({text: item.group, games: []});
            }
            item.list.forEach(d => {
              d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
            })
            tmp[index].games = [...tmp[index].games, ...item.list];
          });
          setGameGroups(tmp);
          setMore(result.paginated.more);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  // useEffect(() => {
  //   if (props.active) {
  //     aplus_queue.push({
  //       action: 'aplus.record',
  //       arguments: ['ShowHomePageActivityBanner', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename:popular[0].title}]
  //     });
  //   }
  // }, [props.active]);

  const clickGameOfTheNewGame = (game) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickGameOfTheNewGame', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), tabname: '今日首发', gamename:game.game_name, agent: agent}]
    });
  }

  return <div className="new-today">
    {gameGroups.map((gameGroup,index) => <dl>
      <dt>
        {(() => {
          if (gameGroup.text == moment().format('YYYY-MM-DD')) {
            return '今日首发';
          } else if (gameGroup.text == moment().subtract(1, 'd').format('YYYY-MM-DD')) {
            return '昨日首发';
          } else {
            return gameGroup.text;
          }
        })()}
      </dt>
      <dd>
        {gameGroup.games.map(game => <Link to={'/game?id=' + game.game_id} className="new-today-item" onClick={() => clickGameOfTheNewGame(game)}>
          <div className="icon">
            <Image src={game.game_image?.thumb}/>
            {
              game?.lablePosition == 2 && game.top_lable != '' ?
              <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                {game.top_lable}
              </div>
              : null
            }
          </div>
          <div className="subject">
            <div className="text">
              <div className="name" style={{width: game.discount < 1 ? '85%' : ''}}>
                <div className={"named" + (game.nameRemark ? " name-new" : "") }>{game?.lablePosition == 1 && game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name}</div>
                {game.nameRemark != '' ? <div className="nameRemark-new">{game.nameRemark}</div> : null}
                {/* <div className="time">
                  <span>{game.start_time.split(':')[0]}</span>
                  <i>:</i>
                  <span>{game.start_time.split(':')[1]}</span>
                </div> */}
              </div>
              <div className="info1">
                {/* {
                  game.nameRemark ?
                  <span className="nameRemark">
                    {game?.nameRemark}&nbsp;
                  </span>
                  : null
                } */}
                {game.game_classify_type.trim()}
                {/* {
                  game.nameRemark ?
                  <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                  :
                  <>{game.game_classify_type.trim()}</>
                } */}
                {/* {!game.nameRemark && game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null} */}
                {game.game_species_type == 3 ? " " : <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</>}

                {index == 0 ? 
                <div className="time">
                  <span>{game.start_time.split(':')[0]}</span>
                  <i>:</i>
                  <span>{game.start_time.split(':')[1]}</span>
                </div>
                : null}
              </div>
              <div className="info2">
                {game.game_desc ? <>{game.game_desc?.split('+').map(item => <div className="tag">{item}</div>)}</> : <><span style={{color: game.infoColor}}>{game.introduction}</span></>}
              </div>
              {
                game.bottom_lable ? 
                <>
                {
                  Object.keys(game.bottom_lable).length > 0 ?
                  <div className="info3">
                      <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                  </div>
                  :null
                }
                </>
              :null}
              {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}
            </div>
            {/* <div className="btn">{game.game_species_type == 3 ? "开始" : "下载"}</div> */}
          </div>
        </Link>)}
      </dd>
    </dl>)}
    {gameGroups.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};