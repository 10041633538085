import {useEffect, useState} from "react";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

export default props => {
  const[codes, setCodes] = useState([]);
//   console.log(codes);
  const load = (parms = {}) => {
    Dialog.loading();
    general.axios.post('/user/rebate/getRebateDetail', {rebate_id: props.query.id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if (data) {
            setCodes(data.codes);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    props.setOperate(<CopyToClipboard text={codes} onCopy={() => Dialog.info('已复制')}><div>一键复制</div></CopyToClipboard>);
  }, [codes]);

  return <div className="rebate-codes">
    <div className="info">
        {codes.map((items) => 
        <div className="data">
            <div className="name">{items}</div>
            <CopyToClipboard text={items} onCopy={() => Dialog.info('已复制')}><div className="operate">复制</div></CopyToClipboard>
        </div>
        )}
    </div>
  </div>;
};