import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import {useUpdateEffect, useScroll} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import {AndroidOutlined, AppleOutlined} from "@ant-design/icons";

export default props => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [trades, setTrades] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('/collectedTrades', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          setInit(true);
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }
          setTrades(trades => parms.page === 1 || !parms.page ? result.account_list : [...trades, ...result.account_list]);
          setMore(result.paginated.more);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  // useUpdateEffect(() => {
  //   if (props.active && (!init || props.action === 'POP')) {
  //     load();
  //   }
  // }, [props]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20  > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  return <div className="favorite-trade">
    {trades.map(trade => <Link to={'/trade/detail?id=' + trade.trade_id} className="item">
      <div className="info">
        <div className="icon"><Image src={trade.account_icon} alt=""/></div>
        <div className="text">
          <div className="name">
            {/* 区服：{trade.server_name} */}
            <div className={" " + (trade.nameRemark ? " name-new" : " nonameremark")}>
              <div className={'type type-' + trade.game_species_type}/>
              <div className="gamename">
              {trade.game_name}
              </div>
            </div>
            {trade?.nameRemark != '' ? <div className="nameRemark-new">{trade.nameRemark}</div> : null}
          </div>
          {/* <div className="desc">{trade.title}</div> */}
          <div className={"desc title "}>
            {/* {trade.game_device_type != 1 ? <AppleOutlined className="ios"/> : null} */}
            {/* {trade.game_device_type != 2 ? <AndroidOutlined className="android"/> : null} */}
            {trade.title}
          </div>
        </div>
      </div>
      <div className="data">
        <div className="time">上架时间：{moment(trade.sell_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
        <div className="price">
          <div className="amount">{trade.total_amount}</div>
          <div className="price">{trade.trade_price}</div>
        </div>
      </div>
    </Link>)}
    {trades.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    {trades.length === 0 ? <div className="recovery-empty"><img src="/resources/user/game_empty.png" alt=""/>暂未收藏任何交易</div> : null}
  </div>;
};