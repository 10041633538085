import {CommentOutlined, LikeOutlined, EditOutlined} from "@ant-design/icons";
import moment from "moment";
import {useEffect, useRef, useState} from "react";
import general from "../../general";
import $ from "jquery";
import {useLockFn, useScroll, useUpdateEffect} from "ahooks";
import {NavLink} from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {Link} from "react-router-dom";

export default props => {
  const [commentLoading, setCommentLoading] = useState();
  const [comment, setComment] = useState({});
  const [loaded, setLoaded] = useState(false);
  const commentLoad = () => {
    if (commentLoading) {
      return;
    }
    setCommentLoading(true);
    Dialog.loading();
    general.axios.post('/base/comment/getCommentDetail', {id: props.query.id})
        .finally(() => {
          setCommentLoading(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setComment(data);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    commentLoad();
  }, []);

  const [replyLoading, setReplyLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const replyLoad = (parms = {}) => {
    if (replyLoading) {
      return;
    }
    setReplyLoading(true);
    Dialog.loading();
    let tmp = {
      comment_id: props.query.id,
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/base/comment/getCommentListForComment', tmp)
        .finally(() => {
          setReplyLoading(false);
          Dialog.close();
        })
        .then(res => {
          if (parms.page) {
            setPage(parms.page);
          }
          let {status, data: {comment_list: list, paginated}} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
            setMore(paginated.more && list.length > 0);
          }
          setLoaded(true);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    replyLoad();
  }, []);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        commentLoad();
        replyLoad({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        replyLoad({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  const like = useLockFn(async (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    let data = index?.id === comment.id ? comment : datas[index];
    if (parseInt(data.me_like)) {
      return;
    }
    await general.axios.post('/base/comment/likeComment', {id: data.id})
        .finally(() => {
          Dialog.close();
        })
        .then(response => {
          let {status} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            if (data.id === comment.id) {
              setComment(data => ({...data, me_like: 1, like_count: parseInt(data.like_count) + 1}));
            } else {
              setDatas(datas => {
                datas[index] = {...datas[index], me_like: 1, like_count: parseInt(datas[index].like_count) + 1};
                return [...datas];
              });
            }
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  const ref = useRef(null);
  const onReply = reply => {
    setReply(reply);
    if (ref) {
      ref.current.focus();
    }
  }
  const [content, setContent] = useState('');
  const [reply, setReply] = useState();
  const [inputing, setInputing] = useState();
  const submit = useLockFn(async () => {
    Dialog.loading();
    await general.axios.post('/base/comment/commentForComment', {
      comment_id: comment.id,
      reply_id: reply?.id ?? null,
      content: content,
    })
        .finally(() => {
          Dialog.close();
        })
        .then(response => {
          let {status} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            Dialog.success('操作成功', () => {
              setContent('');
              commentLoad();
              replyLoad({page: 1});
            });
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  return <div className="comment-detail">
    {comment.reward_intergral_amount ? <div className="comment-reward"
                                            style={{backgroundImage: 'url(/resources/comment/reward.png)'}}><div className="text">{comment.reward_intergral_amount * 1}</div></div> : null}
    <div className="comment-user">
      <Link to={'/user/record?id=' + comment.user_id} className="avatar"><Image src={comment.user_icon} alt=""/></Link>
      <div className="info">
        <div className={"name " + (comment.user_id == '928440' ? 'authority' : '')}>{comment.user_nickname}
        {/* <img src={'/resources/user/' + comment.user_level + '.png'}/> */}
        </div>
        <div className="time">{moment(comment.time * 1000).format('MM-DD HH:mm')}</div>
      </div>
    </div>
    <div className="comment-body">
      <div className={"comment-content " + (comment.user_id == '928440' ? 'authority' : '')}>{comment.content}</div>
      <div className="comment-img">
        {comment.pic_list?.map((img, index, imgs) => <Link to={'/img?imgs=' + imgs + '&img=' + index} className="item">
          <Image src={img} alt=""/>
        </Link>)}
      </div>
    </div>
    <div className="comment-info">
      <div className="title">精彩回复</div>
      <div className="count">（阅读{comment.view_count}）</div>
    </div>
    <div className="comment-reply">
      {datas.map((reply, index) => <div className="reply-item" onClick={() => onReply(reply)}>
        <Link to={'/user/record?id=' + reply.user_id} className="avatar"><Image src={reply.user_logo} alt=""/></Link>
        <div className="main">
          <div className="name">{reply.user_nickname}<img src={'/resources/user/' + reply.user_level + '.png'}/>
          </div>
          {/* <div className="time">{moment(reply.time * 1000).format('MM-DD HH:mm')}</div> */}
          <div className="content">{reply.content}</div>
        </div>
        
        {reply.reward_intergral_amount ? <div className="comment-reward"
                                                style={{backgroundImage: 'url(/resources/comment/reward.png)'}}><div className="text">{reply.reward_intergral_amount * 1}</div></div> : null}

        <div className={'like ' + (parseInt(reply.me_like) ? ' active' : '')} onClick={(e) => like(e, index)}><LikeOutlined className="icon"/>{reply.like_count}</div>
      </div>)}
      {datas.length === 0 && loaded ? <div className="recovery-empty"><img src="/resources/message/message_empty.png" alt=""/>咦~什么都没有...</div> : null}
    </div>
    <div className={'comment-operate' + (inputing ? ' inputing' : '')}>
      <div className="container">
        <div className="input">
          <EditOutlined className="icon"/>
          <input type="text" placeholder={reply ? '回复' + reply.user_nickname + '的评论' : '写评论'} value={content} ref={ref} onFocus={e => setInputing(true)}
                 onBlur={() => {
                   if (content) {
                     setReply(null);
                   }
                   setInputing(false);
                 }} onKeyUp={e => e.keyCode === 13 && submit()}
                 onChange={e => setContent(e.target.value)}/>
          {content || inputing ? <div className={'btn' + (content ? ' active' : '')} onClick={submit}/> : null}
        </div>
        {!inputing ? <div className="count">
          <div className="item"><CommentOutlined className="icon"/>{comment.reply_count}</div>
          <NavLink className={'item ' + (parseInt(comment.me_like) ? ' active' : '')} onClick={e => like(e, comment)}>
            <LikeOutlined className="icon"/>{comment.like_count}</NavLink>
        </div> : null}
      </div>
    </div>
  </div>
}