import general from "../../general";
import {useEffect, useState} from "react";
import {prod} from "../../env";
import Dialog from "../../lib/Dialog";
import useActiveState from "../../lib/useActiveState";
import {useLocalStorageState, useLockFn} from "ahooks";
import { CloseOutlined } from "@ant-design/icons";

const tasks = [
  {
    name: '每日登陆游戏',
    reward: '＋2金币',
    id: 'loginGame',
    info: '每日登录游戏就可领',
    operate: '去完成',
    operalias: 'go-complate',
  },
  {
    name: '每日首充',
    reward: '＋8金币',
    id: 'recharge',
    info: '每日只要首充，即可获得奖励',
    operate: '去完成',
    operalias: 'go-complate',
  },
  {
    name: '累计签到',
    reward: '＋100金币',
    id: 'CumulativeSignIn',
    info: '累计签到58次即可获得100金币奖励',
    operate: '去完成',
    operalias: 'go-complate',
  },
  {
    name: '优质评价',
    reward: '＋30金币',
    id: 'goodComment',
    info: '优质评价让你金币拿到手软',
    operate: '去完成',
    operalias: 'go-complate',
  },
  {
    name: '充值满100元',
    reward: '＋50金币',
    id: 'Recharge100',
    info: '累计充值100元，即可获得奖励',
    operate: '去完成',
    operalias: 'go-complate',
  },
  {
    name: '充值满1000元',
    reward: '＋666金币',
    id: 'Recharge1000',
    info: '累计充值1000元，即可获得奖励',
    operate: '去完成',
    operalias: 'go-complate',
  },
];
// const router = [];

export default props => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [config] = useActiveState('config');
  const [user,setUser] = useActiveState('user');
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [cdk, setCdk] = useState('');
  const [cdkCode, setCdkCode] = useState(!prod ? '12345' : '');
  const [cdkIng, setCdkIng] = useState(false);
  const [weixinText, setWeixinText] = useState([]);
  const [alert, setAlert] = useState(false);
  const [item, setItem] = useState();

  // const [isDY, setIsDy] = useState(false);
  // const [newTaskList, setNewTaskList] = useLocalStorageState('task-navlists', tasks);
  // const [TaskList, setTaskList] = useLocalStorageState('task-lists', []);

  let [agent] = useActiveState('agent');
  let [app] = useActiveState('app');

  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios.post('/user/Task2407/getNoviceWelfareData')
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          setLoaded(true);
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            setData(result.data);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  // console.log(data);

  useEffect(() => {
    if (props.active && (!loaded || props.action === 'POP')) {
      load();
    }
  }, [props.active]);

  const [receiving, setReceiving] = useState(false);

  const handle = task => {
    // let state = data[task.id];
    if (!task.isReceivedReward) {
      if (task.isCompleted) {
        if (receiving) {
          return;
        }
        setReceiving(true);
        Dialog.loading();
        general.axios.post('/user/task/takeBalance', {name: task.name})
          .finally(() => {
            setReceiving(false);
            Dialog.close();
          })
          .then(res => {
            let result = res.data;
            if (!result.status.succeed) {
              Dialog.error(result.status.error_desc);
            } else {
              Dialog.success('领取成功');
              // data[task.id].isCompleted = true;
              // data[task.id].isReceivedReward = true;
              // setData({...data});
              load();
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
      } else {
        if (task.name === 'mobile') {
          general.history.push('/user');
        } else if (task.name === 'authentication') {
          setIsCheckAuth(true);
        } else if (task.name === 'weixin') {
          setCdk(task.name);
          let tipwx = task.tips.split('\n');
          setWeixinText(tipwx);
        }else{
          setItem(task)
          setAlert(true)
        }
      }
    }
  }

  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;

        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
          load()
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  const cdkHandle = () => {
    if (!cdkCode) {
      Dialog.error('请输入兑换码');
      return;
    }
    if (cdkIng) {
      return;
    }
    setCdkIng(true);
    Dialog.loading();
    general.axios.post('/user/task/exchange', {type: cdk, code: cdkCode})
        .finally(() => {
          setCdkIng(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            load()
            Dialog.success('领取成功');
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  return <div>
    <div className="task-welfare" >
      <div className="welfare-body" style={{backgroundImage: 'url(/resources/task/welfare-body-bg2.png)'}}>
        <div className="container">
          <div className="tasks">
            {data.map(item => <div className="task" onClick={() => handle(item)}>
              <div className="icon"><img src={item.icon}/></div>
              <div className="info">
                <div className="name">
                  {item.title}
                  <br/>
                  {
                    item.name != 'Recharge1000' && item.name != 'Recharge100' ?
                    <>
                    {item.total > 0 ? <div className="frequency">({item.completedTotal}/{item.total})</div> : null}
                    <div className="reward">{item.rewardDesc}</div>
                    </>
                    : null
                  }
                  {/* <div className="reward">{item.rewardDesc}</div> */}
                </div>
                {
                  item.name == 'Recharge1000' || item.name == 'Recharge100' ?
                  <div className="weird">
                  {item.total > 0 ? <div className="frequency">({item.completedTotal}/{item.total})</div> : null}
                  <div className="reward">{item.rewardDesc}</div>
                  </div>
                  : null
                }
                <div className="desc">{item.remark}</div>
              </div>
              <div className={'operate ' + (item.isCompleted ? (item.isReceivedReward ? 'completed' : 'complete') : '')}>
                <img src={'/resources/task/' + (item.isReceivedReward ? 'completed-ground' : (item.isCompleted ? 'receive-ground' : 'go-ground')) + '.png' } />
                <div style={{position:'absolute',left:'0',right:'0',top:'15px',color:'white',fontSize:'14px'}}>{item.btnTitle}</div>
              </div>
            </div>)}
            {/* {tasks.map(task => data[task.id]? <div className="task" onClick={() => handle(task)}>
              <div className="icon"><img src={'/resources/task/welfare-' + (task.id) + '.png'} alt=""/></div>
              <div className="info">
                <div className="name">
                  {task.name}
                  <div className="reward">+{data[task.id]?.balance}金币</div>
                </div>
                <div className="desc">{task.info}</div>
              </div>
              <div className={'operate' + (data[task.id]?.completed ? (data[task.id]?.taked ? ' completed' : ' complete') : '')}>
                <img src={'/resources/task/'+ (data[task.id]?.completed ? (data[task.id]?.taked ? 'been- completed' : 'go-receive') : (task.operalias)) +'.png'} />
              </div>
            </div>:null)} */}
          </div>
        </div>
      </div>
      {
      isCheckAuth?
      <div className="pay-check-auth">
        <div className="auth-index">
          <div className="close" onClick={() => setIsCheckAuth(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>实名认证</h3>
              <p>
                {config.authTopTips}
                {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
              </p>
            </div>
          </div>

          <div className="auth-user-realname">
            <div className="auth-user-input">
              <div className="name">真实姓名</div>
              <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
            </div>
            <div className="auth-user-input">
              <div className="name">身份证号</div>
              <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
            </div>
            <span>
              {config.authBottomTips}
            {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
            </span>
            <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
          </div>
        </div>
      </div>
      : null
      }

      {cdk ? <div className={'cdk ' + cdk}>
            <div className="mask" onClick={() => setCdk(null)}/>
            <div className="entity" style={{backgroundImage: 'url(/resources/task/novice-cdk.png)'}}>
              <div className="head"><img src={'/resources/task/' + cdk + '-title.png'} alt=""/></div>
              {cdk === 'weixin' ? <div className="info">
                任务流程<br/>
                {weixinText.map(e=><p>{e}</p>)}
              </div> : null}
              <div className="input">
                <input type="text" value={cdkCode} placeholder="请输入兑换码" autoComplete={false} onChange={e => setCdkCode(e.target.value)} onKeyUp={e => e.keyCode === 13 && cdkHandle()}/>
                <div className="btn" onClick={() => cdkHandle()}>确认</div>
              </div>
              {cdk === 'weixin' ? <a href="weixin://" className="operate">前往微信</a> : null}
            </div>
            <div className="close" onClick={() => setCdk(null)}>X</div>
          </div> : null}

      {alert ? <div className="welfare-cdk">
        <div className="mask" onClick={() => setAlert(false)}/>
        <div className="entity">
          <div className="title">
            <span>{item.alert_title}</span>
            <div className="close" onClick={() => setAlert(null)}><CloseOutlined style={{color:'#999'}}></CloseOutlined></div>
          </div>
          <div className="desc" style={{padding:'10px 0 120px 0'}}>
            <p dangerouslySetInnerHTML={{__html:item.alertMsg.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r)/g, '$1<br>$2')}}></p>
          </div>
        </div>
      </div> : null}

    </div>
  </div>;
};