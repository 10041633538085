import React, { useEffect, useState } from 'react'
import { Avatar, Input } from 'antd-mobile'
import { SearchOutlined } from '@ant-design/icons'
import './css/TeamMembers.css'
import general from '../../../../general'
import Dialog from '../../../../lib/Dialog'

export default function TeamMembers(props) {

  const [members,setMembers] = useState([])
  const [list,setList] = useState([])
  const [search,setSearch] = useState(false)

  const getMembers = ()=>{
    Dialog.loading()
    general.axios.post('/base/yunxin/teamListUsers',{tid:props.query.tid})
    .finally(()=>{
      Dialog.close()
    })
    .then(res=>{
      const {status,data} = res.data
      if(status.succeed){
        setMembers(data)
        setList(data)
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  useEffect(()=>{
    if (props.active){
      getMembers()
    }
  },[props.active])

  const enterPress = (value)=>{
    setSearch(value.length > 0)
    if (value.length > 0){
      let array = []
      for (let i =0;i<members.length;i++){
        const member = members[i]
        let filters = {title:member.title,list:[]}
        for (let j=0;j<member.list.length;j++){
          const obj = member.list[j]
          if (obj.nickname.search(value) != -1){
            filters.list.push(obj)
          }
        }
        array.push(filters)
      }
      const searchArr = array.filter(item=>{
        return item.list.length > 0
      })
      setList(searchArr)
    }else{
      setList(members)
    }
  }

  return (
    <div className='team-members-index'>
      <div className='team-members-container'>
        <div className="search">
          <div className="searchbar">
            <SearchOutlined className='search-icon'></SearchOutlined>
            <Input onChange={enterPress} placeholder='请输入用户昵称' clearable onEnterPress={enterPress}></Input>
          </div>
        </div>
        <div className="list">
          {list.map((item,idx)=>{
            return <div id={item.title === '群主' ? '#' : item.title} className='item' key={idx}>
              <div className="tag">{item.title}</div>
              {item.list.map(member=>{
                return <div className="member">
                  <Avatar src={member.icon} key={member.id} fallback={
                    <img src={'resources/user/avatar.png'}></img>
                  } style={{ 
                    '--size': '45px',
                    '--border-radius': '22px'
                  }}></Avatar>
                  <div className='name'>{member.nickname}</div>
                </div>
              })}
            </div>
          })}
        </div>
        <div className="action">
          {list.map((item,idx)=>{
            return <div onClick={()=>{
              document.querySelector(`#${item.title}`).scrollIntoView({
                behavior:'smooth'
              })
            }}>
              {(()=>{
                if (item.title === '群主'){
                  return '#'
                }else if( item.title === '管理员'){
                  return ''
                }else{
                  return item.title
                }
              })()}
            </div>
          })}
        </div>
      </div>
    </div>
  )
}
