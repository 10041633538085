import {Link} from "react-router-dom";

export default () => {
  return <div className="transfer-index">
    <img src="/resources/transfer/img.png" alt=""/>
    <div className="body">
      <div className="title">转游规则</div>
      <div className="info">ps：目前只支持BT游戏之间转游，及折扣和H5、GM转入BT游戏。折扣、H5、GM不接受转入。详情以与客服沟通的内容为准。（转游或转区必须在同账号下进行）</div>
      <Link to="/service" className="btn">咨询客服</Link>
    </div>
  </div>;
};