import React, {useEffect, useState, useRef, useCallback} from "react";
import general from "../../general";
import $ from "jquery";
import {useLockFn, useScroll, useUpdateEffect, useMount} from "ahooks";
import Dialog from "../../lib/Dialog";
import Operate from "./Operate";
import Image from "../../lib/Image";
import moment from "moment";
import {Link} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import useUrlState from "@ahooksjs/use-url-state";
import MobileDetect from "mobile-detect";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default props => {
  const chatListRef = useRef(null)
  const [message, setMessage] = useState('');
  const [datas, setDatas] = useState([]);
  const [info, setInfo] = useState([]);
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useActiveState('user');
  const [app] = useActiveState('app');
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [isSlid, setIsSlid] = useState(1);

  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    tmp.id = params.id;
    general.axios.post('getMessages', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        if (parms.page) {
          setPage(parms.page);
        }
        let {info, list, paginated} = data;
        // let tmp = [];
        // for (const key in groups) {
        //   tmp.push(groups[key]);
        // }

        if(parms.page > 1) {
          //翻页滑动位置
          setIsSlid({isSlid: isSlid + 1});
          let top = chatListRef.current.scrollHeight / datas.length * list.length;
          // $('html,body').animate({scrollTop: chatListRef.current.scrollHeight / datas.length * list.length});
          $('html,body').scrollTop(top);
        }

        //反转数组
        let list2 = list.reverse();
        setLoaded(true);
        setDatas(datas => parms.page === 1 || !parms.page ? list : [...list2, ...datas]);
        setInfo(info);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  // console.log(info);

  useEffect(() => {
    props.active && load({page: 1});
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    // console.log(scroll.top);
    if (props.active) {
      if (scroll.top == 0 && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  // const [groupEl, setGroupEl] = useState();
  // const groupRef = useCallback(node => {
  //   if (node) {
  //     setGroupEl(node);
  //   }
  // }, []);

  useEffect(() => {
    const current = chatListRef.current;

    //scrollHeight是页面的高度
    current.scrollTop = current.scrollHeight;

    //进页面固定到底部
    if(isSlid == 1) {
      $('html,body').animate({scrollTop: current.scrollHeight}, 250);
    }
    
    // $('html,body').animate({scrollTop: current.scrollHeight}, 250);
  }, [chatListRef, datas]);

  //发送消息
  const sendMessage = () => {
    if(message == '') {
      Dialog.info('回复内容不能为空');
      return;
    }

    let tmp = {};
    tmp.content = message;
    tmp.toUid = info.fromUid;
    tmp.taskId = info.taskId;
    general.axios.post('sendMessage', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setMessage('');
        load({page: 1});
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  };

  return <div className="message-infobox">
    <div className="infobox-body" ref={chatListRef} >
      {
        datas?.map((item, index) => <>
          {(()=>{
            if(item.type == '1') {
              return <div className="left-content message">
                <div className="avatar">
                  <Image src={info?.agentAvatar} alt=""/>
                </div>
                <div className={"info " + (item.messageType == 'game' ? ' type-game-message' : '')} >
                  <div className="nickname">
                    {info?.agentName}
                  </div>
                  {item.messageType == 'game' ?
                    <div className="content-index game_info">
                      <div className="item-base">
                        <div className="icon">
                          <img src={item.gameInfo.gameLogo} />
                        </div>
                        <div className="text">
                          <div className="name">{item.gameInfo.gameName}</div>
                          <div className="info1">{item.gameInfo.tags}｜{item.gameInfo.howManyPla}人在玩｜{mobileDetect.os() == 'AndroidOS' ? item.gameInfo.gameSize.androidSize : item.gameInfo.gameSize.iosSize}</div>
                        </div>
                      </div>
                      <div className="desc">
                        {item.content}
                      </div>
                      <Link to={"/game?id=" + item.gameInfo.gameId} className="operate">下载游戏></Link>
                    </div>
                  : 
                    <div className="content-index text-info">
                      <div>
                        {item.content}
                      </div>
                    </div>
                  }
                </div>
              </div>
            } else if(item.type == '2') {
              return <div className="right-content message">
                <div className="info">
                  <div className="nickname">
                    {user?.nick_name}
                  </div>
                  <div className="content-index text-info">
                    <div>
                      {item.content}
                    </div>
                  </div>
                </div>
                <div className="avatar">
                  <Image src={user.avatar_status === '-1'? '/resources/user/avatar.png' : (user.icon_link || '/resources/user/avatar.png')} alt=""/>
                </div>
              </div>
            }
          })()}
        </>)
      }

      {/* <div className="left-content message">
        <div className="avatar">
          <img src="http://static-cdn.app.wakaifu.com/avatar/985sy/default/1.png!avatar" />
        </div>
        <div className="info">
          <div className="nickname">
            咪噜游戏
          </div>

          <div className="content-index game_info">
            <div className="item-base">
              <div className="icon">
                <img src="https://static.app.985sy.com/attachment/syapp/logo/202208091660030958.jpg!icon_128?v=10" />
              </div>
              <div className="text">
                <div className="name">testtest</div>
                <div className="info1">益智 解谜｜2.3万人在玩｜372.92MB</div>
              </div>

            </div>
            <div className="desc">
              您感兴趣的魔幻类史诗巨制新游上线，独家福利，送顶级红武，海量代金券！
            </div>
            <Link className="operate">下载游戏></Link>
          </div>

        </div>
      </div>

      <div className="right-content message">
        <div className="info">
          <div className="nickname">
            heiheihei
          </div>
          <div className="content-index text-info">
            <div>
              aaaaaaaaaa
            </div>
            
          </div>
        </div>

        <div className="avatar">
          <img src="http://static-cdn.app.wakaifu.com/avatar/985sy/default/1.png!avatar" />
        </div>
      </div> */}
    </div>

    <div className="info-operate width">
      <div className="container">
        <div className="input">
          <input type="text" value={message} placeholder="请输入回复内容" onChange={e => setMessage(e.target.value)} style={{wordBreak:"break-all"}}/>
          {/* <textarea onChange={e => setMessage(e.target.value)} >
          {message}
          </textarea> */}
        </div>
        <div className="btn" onClick={()=>{sendMessage()}}>
          发表
        </div>
      </div>
    </div>
  </div>;
};