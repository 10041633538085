import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Can from "./Can";
import Record from "./Record";
import {useLocalStorageState} from "ahooks";
import KeepScroll from "../../lib/KeepScroll";

const types = [
  {
    id: 'can',
    text: '申请返利',
    component: Can,
  },
  {
    id: 'record',
    text: '申请记录',
    component: Record,
  },
];

export default props => {
  useEffect(() => {
    props.setOperate(<Link to="/rebate/guide" className="rebate-index-guide">返利指南</Link>);
  }, []);
  // const [typeIndex, setTypeIndex] = useLocalStorageState('rebate-type', 0);
  const [typeIndex, setTypeIndex] = useState(0);

  return <div className="rebate-index">
    <div className="rebate-head">
      {types.map((item, index) => <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>{item.text}</div>)}
    </div>
    <div className="rebate-body">
      {types.map((type, index) =>
          <div className={'type-item ' + (index === typeIndex ? ' active' : '')}>
            <KeepScroll active={index === typeIndex}>
              <type.component active={props.active && index === typeIndex}/>
            </KeepScroll>
          </div>)}
    </div>
  </div>;
};