import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import List from "./List";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";
import general from "../../general";
import {NavLink} from "../../lib/Navigation";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";
import Subtitle from "../components/Subtitle";

export default props => {
  useEffect(() => {
    props.setOperate(<NavLink to="/user/voucher" className="voucher-index-operate">我的代金券</NavLink>);
  }, []);
  const [kw, setKw] = useState('');
  const [title, setTitle] = useState();
  const [datas, setDatas] = useState([]);
  const load = () => {
    Dialog.loading();
    general.axios.get('/base/voucher/getLatePlayGames')
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setTitle(data.title);
        setDatas(data.list.map(item => ({...item, ...item.game_info})));
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  useEffect(() => {
    load();
  }, []);

  const [inputFlag, setInputFlag] = useState(false);
  const [inputText, setInputText] = useState('');
  let isOnComposition = false;

  const handleComposition = (e) => {
    //中文输入状态
    if (e.type === 'compositionend') {
      isOnComposition = false;
      setInputText(e.target.value);
      setKw(e.target.value);
      return;
    } else if (e.type === 'compositionstart') {
      isOnComposition = true;
      return;
    } else if (e.type === 'compositionupdate') {
      isOnComposition = true;
      return;
    }
    isOnComposition = true;
  }

  const handleChange = (e) => {
    setInputText(e.target.value);
    if (!isOnComposition) {
      setKw(e.target.value)
    }
  }

  return <div className="voucher-index">
    <div className="voucher-head">
      <div className="input">
        <SearchOutlined className="icon"/>
        {/* <input type="text" placeholder="请输入要搜索的游戏" value={kw} onChange={e => setKw(e.target.value)}/> */}
        <input type="text" value={inputText || ''} placeholder="请输入要搜索的游戏"
            onCompositionStart = {handleComposition}
            onCompositionUpdate = {handleComposition}
            onCompositionEnd = {handleComposition}
            onChange = {handleChange}
            />
        {kw || inputText ? <CloseOutlined className="empty" onClick={() => {setKw('');setInputText('')}}/> : null}
      </div>
      <div className="btn">搜索</div>
    </div>
    <div className="voucher-body">
      <div className="voucher-title">{title}<Link to={'/voucher/lateplay?title=' + title} className="more">更多 ></Link>
      </div>
      <div className="lateplay-main">
        <div className="items">
          {datas.map(data => <Link to={'/game?id=' + data.game_id + '&voucher=1'} className="item">
            <div className="icon"><Image src={data.game_image.thumb}/></div>
            <div className="info">
              <div className="name">
                {/* {data.game_name} */}
                <div className={" " + (data.nameRemark ? " name-new" : "")}>
                  {data.game_name}
                </div>
              </div>
              <div className="desc">
                {/* {
                  data.nameRemark ?
                  <span className="nameRemark">
                    {data?.nameRemark}&nbsp;
                  </span>
                  : null
                } */}
                {/* {
                  data.nameRemark ?
                  <>{data.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                  :
                  <>{data.game_classify_type}</>
                } */}
                {data.game_classify_type}
                <Subtitle>{data.nameRemark}</Subtitle>
                {/* {
                  data.nameRemark ?
                  null
                  : <>&nbsp;|&nbsp;{data.howManyPlay}人在玩</>
                } */}
              </div>
              <div className="voucher"><span>￥</span><span>{data.total_amount}</span>共{data.count_amount}张券</div>
            </div>
          </Link>)}
        </div>
      </div>
      <div className="voucher-title">代金券专区</div>
      <div className="voucher-main"><List active={props.active} kw={kw}/></div>
    </div>
  </div>;
};