import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {RetweetOutlined} from '@ant-design/icons';
import general from "../../general";
import $ from "jquery";
import {useLockFn, useScroll, useUpdateEffect} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import Mask from "./mask/Mask";

export default props => {
  useEffect(() => {
    props.setOperate(<Link to="/recovery/record" className="recovery-record">回收记录</Link>);
  }, []);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const [notice, setNotice] = useState(); //回收须知
  const [waring, setWaring] = useState(); //温馨提示

  // const [init, setInit] = useState(false);
  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      pagination: {page: page, count: 20},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios.post('/recyclableAltGames', tmp)
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data: {info, list, paginated}} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setLoaded(true);
            if (parms.page) {
              setPage(parms.page);
            }

            let notice = info.notice;
            setNotice(notice);
            let waring = info.waring;
            setWaring(waring);

            setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
            setMore(paginated.more && list.length > 0);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useEffect(() => {
    if (props.active) {
      load({page: 1});
    }
  }, [props.active]);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  return <div className="recovery-index">
    <div className="recovery-step">
      <div className="item"><div className="icon"><img src="/resources/recovery/recovery-one.png" /></div><div>选择小号</div></div>
      <div className="icon-step"><img src="/resources/recovery/recovery-four.png" /></div>
      <div className="item"><div className="icon"><img src="/resources/recovery/recovery-two.png" /></div><div>确认提交</div></div>
      <div className="icon-step"><img src="/resources/recovery/recovery-four.png" /></div>
      <div className="item"><div className="icon"><img src="/resources/recovery/recovery-three.png" /></div><div>实时到账</div></div>
    </div>
    {waring && notice && <Mask waring={waring} notice={notice}></Mask>}
    <div className="recovery-title">
      {/* <RetweetOutlined className="icon"/> */}
    可回收小号</div>
    <div className="recovery-items">
      {datas.map(item => <Link to={'/recovery/select?id=' + item.id} className="item" onClick={() => {
        general.game = {img: item.img};
      }}>
        <div className="icon"><Image src={item.img.thumb} alt=""/></div>
        <div className="info">
          <div className="name">
            <div className={" " + (item.nameRemark ? " name-new" : "")}>
              {item.name}
            </div>
            {item.nameRemark != '' ? <div className="nameRemark-new">{item.nameRemark}</div> : null}
          </div>
          <div className="desc">可回收小号：
            <span className="number">{item.recyclable}个</span>&nbsp;&nbsp;
            历史累计充值：{item.rechargedAmount}元
          </div>
          <div className="desc">回收可得：<span className="coin">{item.recyclableCoin}金币</span></div>
        </div>
      </Link>)}
    </div>
    {datas.length === 0 && loaded ? <div className="recovery-empty"><img src="/resources/recovery/empty.png" alt=""/>暂无可回收小号</div> : null}
  </div>;
};