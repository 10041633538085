import React, {useEffect, useState} from "react";
import moment from "moment";
import general from "../../general";
import $ from "jquery";
import {useReactive, useScroll} from "ahooks";
import {Link, useHistory} from "react-router-dom";
import KeepScroll from "../../lib/KeepScroll";
import Explain from "../voucher/Explain";
import Dialog from "../../lib/Dialog";
import { FastForwardFilled } from "@ant-design/icons";

const types = [
  {
    id: 'usable',
    text: '可使用',
  },
  {
    id: 'used',
    text: '已使用',
  },
  {
    id: 'expire',
    text: '已过期',
  }
];

export default props => {
  const [typeIndex, setTypeIndex] = useState(0);
  // const [init, setInit] = useState();
  const [loading, setLoading] = useState(false);
  const datas = useReactive(types.map(item => ({
    id: item.id,
    text: item.text,
    init: false,
    loading: false,
    page: 1,
    more: null,
    datas: [],
    loadState: false,
  })));

  const load = (parms = {}) => {
    let data = datas[typeIndex];
    
    if (data.loading || data.loadState) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    console.log(data.loadState);
    
    let tmp = {
      pagination: {count: 10, page: data.page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    if (data.id === 'usable') {
      tmp.is_expired = 0;
      tmp.is_used = 0;
    } else if (data.id === 'used') {
      tmp.is_used = true;
    } else if (data.id === 'expire') {
      tmp.is_expired = true;
    }
    general.axios.post('/receivedVouchers', tmp)
        .finally(() => {
          data.loading = false;
          Dialog.close();
        })
        .then(res => {
          data.init = true;
          let result = res.data.data;
          if (parms.page) {
            data.page = parms.page;
          }
          data.datas = parms.page === 1 || !parms.page ? result.list : [...data.datas, ...result.list];
          data.more = result.paginated.more;
          if(result.list.length == 0) {
            data.loadState = true;
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active && !datas[typeIndex].init) {
      load();
    }
  }, [props.active, typeIndex]);

  const scroll = useScroll();
  useEffect(() => {
    let data = datas[typeIndex];
    if (props.active && data.init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && data.init) {
        load({page: data.page + 1});
      }
    }
  }, [scroll, props.active]);

  const [explain, setExplain] = useState();

  return <div className="user-voucher">
    <div className="user-voucher-head">
      <div className="items">
        {types.map((item, index) => <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>{item.text}</div>)}
      </div>
      <div className="tips">代金券仅限当前账号登录本APP内游戏充值使用</div>
    </div>
    <div className="user-voucher-body">
      {datas.map((item, index) =>
          <div className={'type-item' + (index === typeIndex ? ' active' : '')}>
            <KeepScroll active={index === typeIndex}>
              {item.datas.length === 0 && item.init ? <div className="empty" style={{backgroundImage: 'url(/resources/voucher/empty.png)'}}/> : null}
              {item.datas.map(voucher =>
                  <div
                      className={'voucher-item ' + item.id + (item.id !== 'usable' ? ' invalid' : '')}
                      style={{backgroundImage: 'url(/resources/voucher/' + (item.id === 'usable' ? (voucher.type == 2 ? 'normal' : 'normal') : 'invalid') + '.png)'}}
                  >
                    {/* <div className={"amounts " + (item.id !== 'usable' ? '' : voucher.type == 2 ? "vip-amounts" : "")} > */}
                    <div className={"amounts " } >
                      {/* {
                        voucher.type == 2 ? 
                          <div className="vip-label">
                            <img src="/resources/game/vip-label.png" />
                            <div className="text">
                              {voucher.vip_level_desc}
                            </div>
                          </div>
                        :null
                      } */}
                      <div className="amount">
                        <div className="amount-unit">￥</div>
                        <div>{voucher.amount * 1}</div>
                      </div>
                      <div className="amount-meet">
                        {(() => {
                          let meet_amount = voucher.meet_amount * 1;
                          if(meet_amount === 0) {
                            return '任意金额可用';
                          } else {
                            return '满' + meet_amount + '元可用';
                          }
                        })()}
                        {/* 满{voucher.meet_amount * 1}元可用 */}
                      </div>
                    </div>
                    <div className="info">
                      <div className={"name " + (voucher.is_available === '0' || item.id !== 'usable' ? 'disable' : '')}>{'仅限' + (voucher.game_type === '' ? `【${voucher.game_name}】使用` : `${voucher.game_name} ${voucher.name}`)}</div> 
                      <div className="row-info">
                        <div className={"vip " + (voucher.is_available === '0' || item.id !== 'usable' ? 'block-disable' : '')} style={{display:voucher.vip_level > 0 ? '' : 'none'}}>VIP专享</div>
                        <div className={"sing-voucher " + (voucher.is_available === '0' || item.id !== 'usable' ? 'block-disable' : '')}>{voucher.game_id.length > 0 ? '单游券' : '通用券'}</div>
                        <div className={"time " + (voucher.is_available === '0' ? 'disable' : '')}>{voucher.useEndDateTime}</div>
                      </div>
                      <div className={"use " + (voucher.is_available === '0' ? 'disable' : item.id !== 'usable' ? ' disable ' : voucher.type == 2 ? "vip-use" : '')} onClick={() => setExplain(voucher)}>使用方法></div>
                    </div>
                    {/* <Link to={voucher.game_id ? ('/game?id=' + voucher.appid + '&mid=' + voucher.game_id) : '/screen-game'} className={"operate " + (item.id !== 'usable' ? '' : voucher.type == 2 ? "vip-operate" : "")} onClick={(e) => {
                      if (!(voucher.is_used == '0' && voucher.is_expired == '0')) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}/> */}
                    <Link to={voucher.game_id ? ('/game?id=' + voucher.appid + '&mid=' + voucher.game_id) : '/screen-game'} className={"operate " + (item.id !== 'usable' ? '' : "")} onClick={(e) => {
                      if (!(voucher.is_used == '0' && voucher.is_expired == '0')) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}/>
                  </div>)}
              {item.datas.length > 6 && !item.more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
            </KeepScroll>
          </div>)}
    </div>
    {explain ? <Explain voucher={explain} setVoucher={setExplain}/> : null}
  </div>;
};