import React, {useEffect, useState} from "react";
import general from "../../general";
import $ from "jquery";
import {useLockFn, useScroll} from "ahooks";
import Dialog from "../../lib/Dialog";
import Operate from "./Operate";
import Image from "../../lib/Image";
import moment from "moment";
import {Link} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";

export default props => {
  useEffect(() => {
    props.setOperate(<Operate type="topic" on={() => load({page: 1})}/>);
  }, []);
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      type: 'topic',
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('mess_list', tmp)
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if (parms.page) {
            setPage(parms.page);
          }
          let {message_list: list, paginated} = data;
          list = list.map(item => {
            item.read_tag = parseInt(item.read_tag)
            return item;
          });
          setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
          setMore(paginated.more && list.length > 0);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useEffect(() => {
    props.active && load();
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  const read = useLockFn(async data => {
    await general.axios.post('mess_operate', {message_mixid: data.message_mixid, operate_type: 1})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          data.read_tag = 1;
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  const remove = data => {
    Dialog.confirm({
      title: '确定删除吗？',
      yes: () => {
        Dialog.loading();
        general.axios.post('mess_operate', {message_mixid: data.message_mixid, operate_type: 2})
            .finally(() => {
              Dialog.close();
            })
            .then(res => {
              let {status} = res.data;
              if (!status.succeed) {
                Dialog.error(status.error_desc);
                return;
              }
              setDatas(datas => datas.filter(item => item !== data));
            })
            .catch(err => {
              Dialog.error(err.message);
            });
      }
    });
  };

  return <div className="message-topic">
    {datas.length > 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};