import {useState, useEffect, useCallback} from "react";
import general from "../../general";
import {useMount, useToggle, useLocalStorageState, useLockFn} from "ahooks";
import {CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";

const mobileDetect = new MobileDetect(navigator.userAgent);
export default props => {
  // useEffect(() => {
  //     // props.setOperate(<Link to="/register" className="rebate-index-guide">注册</Link>);
  //     props.setOperate(<Link to="/verificode" className="rebate-index-guide">验证码登录</Link>);
  // }, []);
  const history = useHistory();
  const {aplus_queue} = window;
  const [app] = useActiveState('app');
  // useMount(() => {
  //   general.callback = props.query.callback || null;
  //   // history.replace('/phonelogin');
  // });
  const [datas, setDatas] = useLocalStorageState('users-0804', !prod ? [
    {username: 'ceshi2', password: 'maiyou123tyc'},
    {username: 'yang004', password: '123456'},
  ] : []);
  const [focus, setFocus] = useState('');
  const [password, {toggle: togglePassword}] = useToggle(true);
  const [data, setData] = useState(datas.length > 0 ? {...datas[0]} : {});
  // const [agreement, setAgreement] = useLocalStorageState('agreement', false);
  const [config, setConfig] = useActiveState('config');
  const [agreement, setAgreement] = useState(true);
  const [loging, setLoging] = useState(false);
  const [user, setUser] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作
  const login = () => {
    if (!data.username) {
      Dialog.error('请输入用户名');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }
    if (!agreement) {
      // Dialog.error('请阅读下方的协议');
      // Dialog.error('咪噜游戏:请先阅读并同意《用户协议》和《隐私政策》');
      Dialog.info('请先阅读并同意《用户协议》和《隐私政策》');
      return;
    }
    if (loging) {
      Dialog.error(loging);
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/user/user/login', data)
        .finally(() => {
          // Dialog.info("login.预返回");
          setLoging(false);
          Dialog.close();
        })
        .then(res => {
          // Dialog.info("login.then方法");
          let {status, data: result} = res.data;
          // Dialog.info(status.succeed);
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            if(status.error_code == '1046') {
              setTimeout(()=>{
                window.history.back();
              }, 1000);
            }
          } else {
            localStorage.setItem('user_id', result.user_id);
            localStorage.setItem('username', data.username);
            localStorage.setItem('token', result.token);
            let tmp = datas.filter(item => item.username !== data.username);
            tmp.unshift(data);
            setDatas(tmp);
            // aplus_queue.push({
            //   action: 'aplus.record',
            //   arguments: ['MobileNumberLoginSuccess', 'CLK', {username: data.username}]
            // });
            // Dialog.info(result.user_id);
            load_load()
            setWhether('true');
            Dialog.success('登录成功', () => general.goBack(true));
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
        // Dialog.info("getMemberInfo.then方法");
      })
      .then(response => {
        // Dialog.info("getMemberInfo.then方法");
        let {status, data} = response.data;
        // Dialog.info(status.succeed);
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
          // console.log(data.member_info);
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['AccountPasswordLoginSuccess', 'CLK', {age: data.member_info.age, sex: data.member_info.sex, level: data.member_info.vip_level, username: data.member_info.username, agent: agent}]
          });
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['login', 'CLK', {username: data.username, agent: agent}]
          });
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  const inputRefs = useCallback(node => {
    if (node) {
      node.addEventListener('input', e => {
        let currentInput = e.data;
        console.log(e.inputType);
        if(e.inputType == 'insertText') {
          setData({...data, username: e.target.value})
        }

        if(e.inputType == 'deleteContentBackward') {
          setData({...data, username: e.target.value})
        }

        if(e.inputType == 'insertFromPaste') {
          setData({...data, username: e.target.value})
        }
      }, {passive: false});
    }
  }, []);

  return <div className="account-login">
    <div className="body">
      <div className="logo"><img src={app.applogo} alt=""/></div>
      {/* <div className="logo"><img src='/resources/user/login_logo.png' alt=""/></div> */}
      <div className="input-item">
        <div className="label">账号</div>
        <div className="input">
          <input name="username" type="text" value={data.username} placeholder="请输入账号/手机号"
                 autoComplete="off"
                 onFocus={e => setFocus('username')}
                 onChange={e => setData({...data, username: e.target.value.toLowerCase()})}
                //  ref={inputRefs}
          />
          {focus == 'username' && data.username
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData({...data, username: ''})}
              />
              : null}
          <div className="assist select">
            <DownOutlined className="select"/>
            <select onChange={e => {
              setData(e.target.value != '' ? datas[parseInt(e.target.value)] : {username: '', password: ''})
            }}>
              <option value="">手动输入</option>
              {datas.map((item, index) => <option value={index} selected={item.username == data.username}>{item.username}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="input-item">
        <div className="label">密码</div>
        <div className="input">
          <input name="password" type={password ? 'password' : 'text'} value={data.password} placeholder="请输入密码"
                 onFocus={e => setFocus('password')}
                 onChange={e => setData({...data, password: e.target.value})}
          />
          {focus == 'password' && data.password
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData(data => ({...data, password: ''}))}/>
              : null}
          <div className="assist password" onClick={() => togglePassword()}>
            {password ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
          </div>
        </div>
      </div>
      <div className="operate clearfix">
        <Link to="/register" className="item">立即注册</Link>
        <Link to="/forget" className="item">忘记密码？</Link>
        {/* <Link to="/register" className="item">快速注册</Link> */}
      </div>
      <div className={'btn ' + (loging ? 'disable' : '')} onClick={login}>登录</div>
      {/*<Link to="/phonelogin" className="btn-phone">手机号登录></Link>*/}
    </div>
    <div className="account-agreement">
      <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        登录即代表您同意
        <NavLink to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api3.app.wakaifu.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
        <NavLink to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api3.app.wakaifu.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>
        {/* <Link to={'/iframe?url=' + url + '/userAgreement&title=用户协议'}>《用户协议》</Link>、
        <Link to={'/iframe?url=' + url + '/privacyPolicy&title=隐私政策'}>《隐私政策》</Link> */}
        <br/>
        接受免除或者限制责任、诉讼管辖约定等粗体标示条款
      </label>
    </div>
  </div>;
};