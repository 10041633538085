import React, {useEffect, useState} from "react";
import general from "../../general";
import $ from "jquery";
import {useLocalStorageState, useLockFn, useReactive, useScroll} from "ahooks";
import KeepScroll from "../../lib/KeepScroll";
import moment from "moment";
import {NavLink} from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import URI from "urijs";

const types = [
  {
    id: 'can',
    text: '待赎回',
  },
  {
    id: 'ed',
    text: '已赎回',
  },
  {
    id: 'expire',
    text: '已过期',
  },
];

export default props => {
  const [typeIndex, setTypeIndex] = useLocalStorageState('recovery-type', 0);
  const [notice, setNotice] = useState(); //赎回须知
  const datas = useReactive(types.map(item => ({
    id: item.id,
    text: item.text,
    init: false,
    loading: null,
    page: 1,
    more: null,
    datas: []
  })));
  const load = useLockFn(async (parms = {}) => {
    let data = datas[typeIndex];
    if (data.loading) { 
      return;
    }
    data.loading = true;
    Dialog.loading();
    let tmp = {
      pagination: {count: 20, page: data.page},
    };
    if (data.id === 'can') {
      tmp.isRedeem = true;
    }
    if (data.id === 'ed') {
      tmp.isRedeemed = true;
    }
    if (data.id === 'expire') {
      tmp.isRedeem = false;
    }
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios.post('/recycledAlts', tmp)
        .finally(() => {
          data.loading = false;
          Dialog.close();
        })
        .then(res => {
          data.init = true;
          let {status, data: {info, list, paginated}} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            if (parms.page) {
              data.page = parms.page;
            }
          }
          let notice = info.noticeItems;
          setNotice(notice);
          data.datas = parms.page === 1 || !parms.page ? list : [...data.datas, ...list];
          data.more = paginated.more && list.length > 0;
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  })
  useEffect(() => {
    if (props.active && !datas[typeIndex].init) {
      load();
    }
  }, [props.active, typeIndex]);
  useEffect(() => {
    if (props.active) {
      load({page: 1});
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    let data = datas[typeIndex];
    if (props.active && data.init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && data.init && data.more) {
        load({page: data.page + 1});
      }
    }
  }, [scroll, props.active]);

  const [data, setData] = useState();
  const [confirm, setConfirm] = useState();
  const [confirmed, setConfirmed] = useState(false);

  const updateAgreement = () => {
    let a = confirmed == true ? false : (confirmed == false ? true : false);
    console.log(a);
    setConfirmed(a);
  }

  return <div className="recovery-record">
    <div className="record-head">
      {types.map((item, index) => <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>{item.text}</div>)}
    </div>
    <div className="record-body">
      {datas.map((type, index) =>
          <div className={'type-item' + (index === typeIndex ? ' active' : '')}>
            <KeepScroll active={index === typeIndex}>
              {type.datas.map(item => <div className={'record-item ' + type.id} onClick={() => {
                if (type.id === 'can') {
                  setData(item);
                  setConfirm(true);
                }
              }}>
                <div className="icon"><Image src={item.game.img.thumb} alt=""/></div>
                <div className="subject">
                  <div className="name">
                      {/* {item.game.name} */}
                      <div className={" " + (item.game.nameRemark ? " name-new" : "")}>
                      {item.game.name}
                    </div>
                    {item.game.nameRemark != '' ? <div className="nameRemark-new">{item.game.nameRemark}</div> : null}
                    </div>
                  <div className="info">
                    <span className="desc">小号昵称：<span>{item.alt.alias}</span></span>&nbsp;&nbsp;
                    <span className="desc">回收成功金额：{item.recycledCoin}金币</span>
                  </div>
                  <div className="operate info">
                  赎回应付：<span>{item.redeemdAmount}元</span>&nbsp;&nbsp;
                    <>
                      {type.id === 'ed' ? <>
                        赎回时间：{moment(item.redeemedTime * 1000).format('MM-DD HH:mm')}
                      </> : <>
                        过期时间：{moment(item.redeemEndTime * 1000).format('MM-DD HH:mm')}
                      </>}
                    </>
                  </div>
                </div>
              </div>)}
              {type.datas.length === 0 && type.init ? <div className="recovery-empty"><img src="/resources/recovery/empty.png" alt=""/>暂无数据</div> : null}
            </KeepScroll>
          </div>)}
    </div>
    {confirm ? <div className="recovery-confirm">
      <div className="confirm-container" style={{backgroundImage: 'url(/resources/recovery/confirm-bg.png)'}}>
        <div className="confirm-head">
          <div className="title">确认赎回小号</div>
          <div className="info">赎回该小号需{data.redeemdAmount}元</div>
        </div>
        <div className="confirm-body">
          <div className="title"><img src="/resources/recovery/title.png" alt=""/>赎回须知</div>
          <div className="content">
            {notice?.map(item => <p>
              {item}
            </p>)}
            {/* <p>回收后72小时内可赎回，逾期不可赎回。</p>
            <p>赎回小号仅支持现金赎回，需额外支付该小号充值金额的3%作为赎回手续费。（例：若小号充值满100元，回收后可获得50积分。若用户需要赎回，则需支付5+3=8元）。</p>
            <p>如有任何疑问，可联系客服咨询！</p> */}
          </div>
        </div>
        <div className="confirm-foot">
          <div className="input"><span className={'agreement' + (confirmed ? ' checked' : '')}  onClick={updateAgreement}/>我已阅读</div>
          <div className="btns">
            <div className="btn" onClick={() => setConfirm(false)}>我再想想</div>
            <NavLink to={(() => {
              let url = URI(data.redeemUrl)
                  .addQuery('from', 'react')
                  .toString();
                return url;
              })()} iframe={{title: '赎回'}}
                     className={'btn ' + (confirmed ? ' confirm' : '')}
                     onClick={e => {
                       if (confirmed) {
                         setConfirm(false);
                       } else {
                         e.preventDefault();
                         e.stopPropagation();
                       }
                     }}>确认赎回</NavLink>
          </div>
        </div>
      </div>
    </div> : null}
  </div>;
};