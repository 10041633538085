import React, { useRef, useState } from 'react'
import './css/HangupFeedback.css'
import ImagePicker from '../../../util/ImagePicker';
import Dialog from '../../../../lib/Dialog';
import general from '../../../../general';

export default function HangupFeedback() {

  const [index,setIndex] = useState(0)
  const [imgs, setImgs] = useState([]);
  const [list] = useState([
    '功能异常：功能故障或不可用',
    '产品建议：用的不爽，我有建议',
    '安全问题：密码、隐私、欺诈等',
    '支付异常：无法支付、支付慢等',
    '其他问题'
  ])
  const contentRef = useRef()

  const submit = () => {
    if (contentRef.current.value.length < 10) {
      Dialog.error('请输入不少于10个字的描述');
      return;
    }
    Dialog.loading();
    var formData = new FormData();
    formData.append('content',contentRef.current.value)
    formData.append('selected_type',list[index])
    imgs.forEach((img, index) => {
      formData.append('imgs[' + index + ']', img.file);
    });
    general.axios.post('/cloudMachineFeedBack', formData)
    .finally(() => {
      Dialog.close();
    })
    .then(response => {
      let result = response.data;
      if (!result.status.succeed) {
        Dialog.error(result.status.error_desc);
      } else {
        Dialog.success('提交成功');
        setTimeout(() => general.history.goBack(), 1000);
      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  return (
    <div className='hangup-feedback-index'>
      <div className="hangup-feedback-container">
        <div className="header">
          <span className='tag'>必选</span>
          <span className='title'>请选择您想反馈的问题点</span>
        </div>
        <div className="list">
          {list.map((item,idx) =>{
            return <div className="item" key={idx} onClick={()=>setIndex(idx)}>
            <img className='icon' src={"resources/hangup/" + (idx == index ? "select" : "default") + ".png"}/>
            <span className='text'>{item}</span>
          </div>
          })}
        </div>
        <textarea ref={contentRef} className='content' placeholder='请描述您在游戏中遇到的问题或向我们提出修改建议，我们会尽快进行处理！'></textarea>
        <span className='desc'>添加图片（最多3张）</span>
        <ImagePicker max={3} multiple={true} files={imgs} setFiles={setImgs}></ImagePicker>
        <div className="submit" onClick={submit}>提交</div>
      </div>
    </div>
  )
}
